import { useEffect, useState } from "react"
import axios from 'axios'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
interface QuoteDataObject {
    message: string,
    author?: string
}

export const RandomQuote = () => {

    const [quoteData, setQuoteData] = useState<QuoteDataObject>(
        {
            message: 'Fetching a Quote!'
        }
    );
    useEffect(
        () => {
            axios.get('https://quotes.rest/qod?category=inspire&language=en')
                .then(
                    (data: any) => {

                        const workingQuote: any = data.data.contents.quotes[0];
                        setQuoteData(
                            {
                                message: workingQuote.quote,
                                author: workingQuote.author
                            }
                        )
                    }
                ).catch(
                    (e) => {
                        setQuoteData(
                            {
                                message: 'Unable to fetch a quote :c'
                            }
                        )
                    }
                )
        }, []);

    return (

        <div className="col-sm-12">
            <div className="card" style={{ borderRadius: 15 }}>
                <div className="card-body p-5">
                    <figure className="text-center mb-0">
                        <blockquote className="blockquote">
                            <p className="pb-3">
                                <FontAwesomeIcon icon={faQuoteLeft} size="xs" className="text-primary mr-2"/>
                                <span className="lead font-italic">{quoteData.message}</span>
                                <FontAwesomeIcon icon={faQuoteRight} size="xs" className="text-primary ml-2"/>
                            </p>
                        </blockquote>
                        <figcaption className="blockquote-footer mb-0">
                            {quoteData.author}
                        </figcaption>
                    </figure>

                </div>
            </div>
        </div>
    );

}