import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';

export const ClientInvoiceTableObject = (props: any) => {
    const invoice = props.invoice;
    const isLate = (): boolean => {

        if (invoice.due_date == null)
            return false;

        return moment().isSameOrAfter(moment.unix(invoice.due_date));
    }

    const rowColor = () => {

        switch (invoice.status) {
            case 'open':
                if (isLate())
                    return 'table-danger'
                break;
            case 'paid': return 'table-success';
            default: return 'table-secondary'
        }
    }

    const sendEmail = () => {
        const con = window.confirm("Are you sure you want to send this invoice?");

        if (!con)
            return;

        props.http.post(`/clients/${props.clientId}/invoices/${invoice.id}/send`).then(
            (success: boolean) => {
                alert(`Email Invoice ${!success ? 'Failed :c' : 'sent'}!`);
            }
        ).catch((e: string) => {
            alert(e);
        });
    }

    const deleteInvoice = () => {
        const con = window.confirm("Are you sure you want to void this invoice?");

        if (!con)
            return;

        props.http.delete(`/clients/${props.clientId}/invoices/${invoice.id}`).then(
            (success: boolean) => {
                alert(`Invoice Voided!`);
                props.reload();
            }
        ).catch((e: string) => {
            alert(e);
        });
    }

    const prettyDate = (time: number) => moment.unix(time).format('LL');

    const buttonDisplay = (invoice: any) => {
        let buttonsToDisplay: any[] = []

        if (invoice.status == 'open') {
            buttonsToDisplay.push(
                {
                    order: 1, view: (
                        <button className='btn btn-small btn-outline-info' onClick={() => sendEmail()}>
                            <FontAwesomeIcon icon={faEnvelope} />
                        </button>)
                },
                {
                    order: 3, view: (
                        <button className='btn btn-small btn-outline-danger' onClick={() => deleteInvoice()}>
                            <FontAwesomeIcon icon={faTrash} />
                        </button>)
                }
            );
        }

        if (invoice.invoice_pdf != null) {
            buttonsToDisplay.push({
                order: 2, view:
                    (
                        <a href={invoice.invoice_pdf} target="_blank" className='btn btn-small btn-outline-success'>
                            <FontAwesomeIcon icon={faEye} />
                        </a>
                    )
            }
            )
        }

        buttonsToDisplay = buttonsToDisplay.sort(
            (a,b)=>a.order-b.order
        )

        return (
            <div className="btn-group pull-right">
                {
                    buttonsToDisplay.map(
                        (v) => v.view
                    )
                }
            </div>
        )
    }

    return (
        <tr key={invoice.id} className={rowColor()}>
            <td >{prettyDate(invoice.created)}</td>
            <td >{invoice.attempted > 0 ? 'Yes' : 'No'}</td>
            <td >${(invoice.amount_due / 100)}</td>
            <td >{invoice.due_date != null ? prettyDate(invoice.due_date) : 'N/A'}</td>
            <td>{invoice.status}</td>
            <td>
                {buttonDisplay(invoice)}
            </td>
        </tr>
    )
}