export interface EditProjectsInterface {
    isLoading:boolean,
    isLive:boolean,
    projectPings:any[],
    projectData:any,
    chartData:{
        options:any,
        series:any
    }|null,
    googleData:{
        options:any,
        series:any
    }|null,
    enablePing:boolean
}

export const initProjectEditValues:EditProjectsInterface = {
    isLoading: false,
    isLive: true,
    projectPings: [],
    projectData: null,
    chartData:null,
    enablePing:false,
    googleData:null
};

export enum EDIT_PROEJECT_ACTIONS {
    IS_LOADING,
    IS_LIVE,
    PROJECT_PINGS,
    PROJECT_DATA,
    CHART_DATA,
    GOOGLE_DATA,
    ENABLE_PING
}

export const editProjectReducer = (state:EditProjectsInterface, action: any):EditProjectsInterface => {
    switch (action.type) {
        case EDIT_PROEJECT_ACTIONS.IS_LOADING: return { ...state, isLoading: action.payload };
        case EDIT_PROEJECT_ACTIONS.IS_LIVE: return { ...state, isLive: action.payload };
        case EDIT_PROEJECT_ACTIONS.PROJECT_PINGS: return { ...state, projectPings: action.payload };
        case EDIT_PROEJECT_ACTIONS.PROJECT_DATA: return { ...state, projectData: action.payload };
        case EDIT_PROEJECT_ACTIONS.CHART_DATA: return { ...state, chartData: action.payload };
        case EDIT_PROEJECT_ACTIONS.ENABLE_PING: return {...state, enablePing:action.payload};
        case EDIT_PROEJECT_ACTIONS.GOOGLE_DATA: return {...state, googleData:action.payload};
        default: return state;
    }
}