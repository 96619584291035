import { useContext, useEffect, useRef, useState } from "react"
import { HttpServiceContext } from "../services/context/HttpServiceContext";
import { HttpService } from "../services/HttpService";
import { ActiveTimer } from "../components/timer/active-timer";
import { TimerTable } from "../components/timer/timer-table";
import { TopbarTitleSetter } from "../components/topbar/topbar-title-setter";
import { ProjectDefaults, ProjectDefaultsContext } from "../services/context/ProjectDefaultsContext";
import '../assets/styles/timer-page.css'
import { useLoadingMessage } from "../services/hooks/loading-message";
import { SkeletonTable } from "../components/skeleton-loaders/skeleton-table";

export const TimeTracker = () => {

    TopbarTitleSetter((
        <h1>Time Tracking</h1>
    ))

    const [message, completed, error, setLoadingMessage] = useLoadingMessage({ message: "", completed: false, error: false })

    const http: HttpService = useContext(HttpServiceContext);
    const [timeTrackers, setTimeTrackers] = useState<{ count: number, items: any[] }>({ count: 0, items: [] })

    const loadTimeTrackers = (start: number = 0) => {
        setLoadingMessage("", false)
        http.get(`/time-tracking/list?start=${start}&tracking=false`).then(
            (res) => {
                const [count, timeTrackers] = res;
                setTimeTrackers({ count, items: timeTrackers });
                setLoadingMessage("", true);
            }
        ).catch(
            (err) => {
                setLoadingMessage("There was an error: " + err, true, true)
            }
        )
    }

    const timerCallback = (stopped: boolean) => {
        if (stopped)
            loadTimeTrackers();
    }

    useEffect(() => {
        loadTimeTrackers();
    }, []);



    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <ActiveTimer timerCallback={timerCallback} />
                </div>
                <div className="col-12 mt-5 extraMargin">
                    <ProjectDefaults>
                        {
                            (completed) ? (
                                <TimerTable timeTrackers={timeTrackers} tableRefreshCallback={() => loadTimeTrackers(0)} pageSelected={(i: number) => loadTimeTrackers((i - 1) * 10)} />
                            ) : (error && completed) ? <h3>{message}</h3> : <SkeletonTable headerNames={["Description", "Project", "Time Spent", "Bill Status", ""]} />
                        }
                    </ProjectDefaults>
                </div>
            </div>
        </div>
    )
}