import { Dispatch, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { PaginateItemProps } from "../../../components/pagination/paginate-items"
import { useErrorMessage } from "../../../services/hooks/error-message";
import { Client } from "../../../services/interfaces/client.interface";
import { HttpService } from "../../../services/HttpService";

interface EditClientsInfoProps {
    client: Client | any,
    httpService: HttpService,
    setClient: Dispatch<any>
}

export const EditClientsInfo = (props: EditClientsInfoProps) => {

    const { client, httpService, setClient } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useErrorMessage("");
    const { register, reset, handleSubmit, formState: { errors, isValid } } = useForm({
        mode: 'all', defaultValues: {
            clientName: '',
            contactName: '',
            contactPhone: '',
            contactEmail: '',
            stripeCustomerId:'',
            contactExtraEmails:''
        }
    });
    const onSubmit = (data: any) => {
        setIsLoading(true);

        httpService.put("/clients/" + client?.id, {
            client: data
        })
            .then((res) => {
                setIsLoading(false);
                const nClient: Client = { id: client.id, ...data } as Client;
                setClient(nClient)
                alert('Client Updated!');
            })
            .catch((e) => {
                if (Array.isArray(e)) {
                    setErrorMessage("Make sure all fields are set!");
                }
                else {
                    setErrorMessage(e)
                }
                setIsLoading(false);
            })

    };

    useEffect(() => {
        reset(client);
        
    }, [client])

    return (


        <form className="" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-row">
                <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                        <label htmlFor="client-name">Client Name</label>
                        <input className={errors.clientName && errors.clientName.message ? "form-control is-invalid" : "form-control"} id="client-name" type="text" placeholder="Client Name" {
                            ...register("clientName",
                                {
                                    required: {
                                        value: true,
                                        message: 'Client Name is required!'
                                    },
                                    minLength: {
                                        value: 1,
                                        message: 'Client Names need to be at least 1 character long!'
                                    }
                                }
                            )} />
                        {errors.clientName && <div className="invalid-feedback">
                            {errors.clientName.message}
                        </div>}
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                        <label htmlFor="client-contact-name">Client Contact Name</label>
                        <input type="text" placeholder="Client Contact Name" className={errors.contactName ? "form-control is-invalid" : "form-control"} id="client-contact-name" {
                            ...register('contactName', {
                                required: {
                                    value: true,
                                    message: 'Client Contact Name is required!'
                                },
                                minLength: {
                                    value: 1,
                                    message: 'Client Contact Names need to be at least 1 character long!'
                                }
                            })
                        } />
                        {errors.contactName && <div className="invalid-feedback">{errors.contactName.message}</div>}
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                        <label htmlFor="client-contact-phone">Client Contact Phone Number</label>
                        <input type="text" placeholder="Client Contact Phone Number" className={errors.contactPhone ? "form-control is-invalid" : "form-control"} id="client-contact-phone" {
                            ...register('contactPhone', {
                                maxLength: {
                                    value: 12,
                                    message: 'Please enter in a max phone number length of 12!'
                                }
                            })
                        } />
                        {errors.contactPhone && <div className="invalid-feedback">{errors.contactPhone.message}</div>}
                    </div>
                </div>
                <div className="col-sm-12 col-md-3">
                    <div className="form-group">
                        <label htmlFor="client-contact-email">Client Contact Email</label>
                        <input type="text" placeholder="Client Contact Email" className={errors.contactEmail ? "form-control is-invalid" : "form-control"} id="client-contact-email" {
                            ...register('contactEmail', {
                                required: {
                                    value: true,
                                    message: 'Client Contact Email is required!'
                                },
                                pattern: {
                                    value: /^\S+@\S+$/i,
                                    message: 'Not a valid email!'
                                }
                            })
                        } />
                        {errors.contactEmail && <div className="invalid-feedback">{errors.contactEmail.message}</div>}
                    </div>
                </div>
                <div className="col-sm-12 col-md-3">
                                <div className="form-group">
                                    <label htmlFor="client-contact-extra-email">Client Extra Emails (comma separated)</label>
                                    <input type="text" placeholder="Client Extra Emails" className={errors.contactExtraEmails ? "form-control is-invalid" : "form-control"} id="client-contact-extra-email" {
                                        ...register('contactExtraEmails', {
                                            
                                        })
                                    } />
                                    {errors.contactExtraEmails && <div className="invalid-feedback">{errors.contactExtraEmails.message}</div>}
                                </div>
                            </div>
                <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                        <label htmlFor="client-stripe-id">Client Stripe ID</label>
                        <input type="text" placeholder="Client Stripe Id" className={errors.stripeCustomerId ? "form-control is-invalid" : "form-control"} id="client-stripe-id" {
                            ...register('stripeCustomerId', {})
                        } />
                        {errors.stripeCustomerId && <div className="invalid-feedback">{errors.stripeCustomerId.message}</div>}
                    </div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-sm-12 col-md-6">
                    <p className="text-danger">{errorMessage.length > 0 && errorMessage}</p>
                </div>
                <div className="col-sm-12 col-md-6">
                    <button className="btn btn-outline-success float-right" disabled={!isValid || isLoading}>Edit</button>
                </div>
            </div>
        </form>


    )
}