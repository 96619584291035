import { useContext, useEffect } from "react";
import { HttpServiceContext } from "../../services/context/HttpServiceContext";
import { PageDetailsProvider } from "../../services/context/PageDetailsContext";
import { HttpService } from "../../services/HttpService";
import { Sidebar } from "../sidebar/sidebar";
import { SideBarToggle } from "../sidebar/sidebarToggleContext";
import { TopBar } from "../topbar/topbar";


export const ActiveTheme = (props: { children: any }) => {

    const http: HttpService = useContext(HttpServiceContext);

    const heartbeat = () => {
        http.post('/auth/heartbeat').then((token: any) => {
            localStorage.setItem("token", token);
        }).catch(console.error)
    }

    useEffect(() => {
        const timer = setInterval(() => {
            heartbeat();
        }, 50000);
        return () => {
            clearInterval(timer);
        }
    }, [])

    return (

        <SideBarToggle>
            <Sidebar></Sidebar>
            <PageDetailsProvider>
                <TopBar></TopBar>
                <div className="contentWrapper">

                    {props.children}

                </div>
            </PageDetailsProvider>
        </SideBarToggle>

    );
}