import { Redirect, Route, Switch } from "react-router";
import { ClientsCreate } from "../../pages/clients/clients-create";
import { ClientsList } from "../../pages/clients/clients-list";
import { EditClientsIndex } from "../../pages/clients/edit";
import { ConfigSettings } from "../../pages/config-settings";
import { Home } from "../../pages/home";
import { Login } from "../../pages/login";
import { EditProjectIndex } from "../../pages/projects/edit";
import { ProjectsCreate } from "../../pages/projects/projects-create";
import { ProjectsList } from "../../pages/projects/projects-list";
import { SystemLogs } from "../../pages/system-logs";
import { TimeTracker } from "../../pages/time-tracker";
import { AllClientsList } from "../../services/context/ClientListContext";
import { ActiveTheme } from "../themes/active-theme";
import { AuthStop } from "./auth-stop";
import { ProtectedRoute } from "./protected-route";

export const AppRouter = ()=>{
    return (
        <Switch>
            <ProtectedRoute exact path="/clients/:clientId/projects/create" render={()=> <ActiveTheme><AllClientsList><ProjectsCreate/></AllClientsList></ActiveTheme>} />
            <ProtectedRoute path="/clients/:clientId/projects/edit/:id" render={()=> <ActiveTheme><AllClientsList><EditProjectIndex/></AllClientsList></ActiveTheme>} />
            <ProtectedRoute path="/all-projects" render={()=> <ActiveTheme><ProjectsList/></ActiveTheme>} />

            <ProtectedRoute path="/clients/create" render={()=> <ActiveTheme><ClientsCreate/></ActiveTheme>} />
            <ProtectedRoute path="/clients/edit/:id" render={()=> <ActiveTheme><EditClientsIndex/></ActiveTheme>} />
            <ProtectedRoute path="/clients" render={()=> <ActiveTheme><ClientsList/></ActiveTheme>} />

            <ProtectedRoute path="/settings" render={()=> <ActiveTheme><ConfigSettings/></ActiveTheme>} />
            
            <ProtectedRoute path="/system-logs" render={()=> <ActiveTheme><SystemLogs/></ActiveTheme>} />
            
            <ProtectedRoute path="/home" render={()=> <ActiveTheme><Home/></ActiveTheme>} />

            <ProtectedRoute path="/time-tracking" render={()=><ActiveTheme><TimeTracker/></ActiveTheme>} />

            <AuthStop path="/login" component={Login}></AuthStop>
            <Route path="/logout" component={()=>{localStorage.clear(); return (<Redirect to="/login"></Redirect>)}}></Route>
            <Redirect to="/login"></Redirect>
        </Switch>
    );
}