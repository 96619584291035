import { useContext, useEffect, useState } from "react";
import { HttpServiceContext } from "../services/context/HttpServiceContext";
import { HttpService } from "../services/HttpService";
import { useForm } from 'react-hook-form';
import { useErrorMessage } from "../services/hooks/error-message";
import { useHistory } from "react-router";
import logo from '../logo.png'
interface loginFormData {
    firstName?: string,
    lastName?: string,
    email: string,
    password: string
}

export const Login = () => {

    const httpService: HttpService = useContext(HttpServiceContext);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isNewInstall, setIsNewInstall] = useState(false);
    const [showNewUser, setShowNewUser] = useState(false);
    const [errorMessage, setErrorMessage] = useErrorMessage("");
    const { register, unregister, handleSubmit, formState: { errors, isValid } } = useForm({ mode: 'all' });

    const unregisterNewUserForms = () => {
        setShowNewUser(false);
        unregister('firstName');
        unregister('lastName');
    }

    useEffect(() => {
        httpService.post('/auth/newinstall').then(
            (exists) => {
                setIsNewInstall(exists);
            }
        )
    }, [])

    const onSubmit = (data: loginFormData) => {
        setIsLoading(true);

        if (data.firstName != null) {
            httpService.post("/users", {
                user: data
            })
                .then((token: any) => {
                    setIsLoading(false);
                    localStorage.setItem("token", token);
                    history.push('/home');
                })
                .catch((e) => {
                    setErrorMessage(e);
                    setIsLoading(false);
                })
        }
        else {
            httpService.post("/auth/login", {
                auth: data
            })
                .then((token: any) => {
                    setIsLoading(false);
                    localStorage.setItem("token", token);
                    history.push('/home');
                })
                .catch((e) => {
                    setErrorMessage(e);
                    setIsLoading(false);
                })
        }



    };

    return (
        <>
            <h2 className="loginTitle"><img src={logo} /></h2>
            <div className="loginBox">
                <form onSubmit={handleSubmit(onSubmit)}>
                    {
                        (isNewInstall && showNewUser) && (
                            <div className="form-row">
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="fname">First Name</label>
                                        <input className={errors.firstName && errors.firstName.message ? "form-control is-invalid" : "form-control"} id="fname" type="text" placeholder="First Name" {
                                            ...register("firstName",
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'This field is required!'
                                                    }
                                                }
                                            )} />
                                        {errors.firstName && <div className="invalid-feedback">
                                            {errors.firstName.message}
                                        </div>}
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="lname">Last Name</label>
                                        <input className={errors.lastName && errors.lastName.message ? "form-control is-invalid" : "form-control"} id="lname" type="text" placeholder="Last Name" {
                                            ...register("lastName",
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'This field is required!'
                                                    }
                                                }
                                            )} />
                                        {errors.lastName && <div className="invalid-feedback">
                                            {errors.lastName.message}
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className="form-row">
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input className={errors.email && errors.email.message ? "form-control is-invalid" : "form-control"} id="email" type="text" placeholder="Email" {
                                    ...register("email",
                                        {
                                            required: {
                                                value: true,
                                                message: 'This field is required!'
                                            },
                                            pattern: {
                                                value: /^\S+@\S+$/i,
                                                message: 'Not a valid email!'
                                            }
                                        }
                                    )} />
                                {errors.email && <div className="invalid-feedback">
                                    {errors.email.message}
                                </div>}
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input className={errors.password && errors.password.message ? "form-control is-invalid" : "form-control"} id="password" type="password" placeholder="Password" {
                                    ...register("password",
                                        {
                                            required: {
                                                value: true,
                                                message: 'This field is required!'
                                            },
                                            minLength: {
                                                value: 4,
                                                message: "Make sure you've entered in your full password!"
                                            }
                                        }
                                    )} />
                                {errors.password && <div className="invalid-feedback">
                                    {errors.password.message}
                                </div>}
                            </div>
                        </div>
                        {
                            errorMessage.length > 0 &&
                            <div className="col-12 text-center">
                                <p className="text-danger">{errorMessage}</p>
                            </div>
                        }
                        <div className="col-12">
                            <button type="submit" className="btn btn-block btn-outline-success" disabled={!isValid || isLoading}>Submit</button>
                            {
                                isNewInstall && (
                                    showNewUser ? (
                                        <button type="button" className="btn btn-block btn-outline-info" onClick={() => unregisterNewUserForms()}>Login Instead</button>
                                    ) : (
                                        <button type="button" className="btn btn-block btn-outline-info" onClick={() => setShowNewUser(true)}>Create User</button>
                                    )
                                )
                            }
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}