import { useEffect, useState } from "react"
import moment from 'moment';
import Chart from 'react-apexcharts';

export const GoogleGraphs = (props:{data:any}) =>{

    const [chartData, setChartData] = useState<any>(null);

    useEffect(() => {

        if(!props.data.rows)
            return;
            
        const sessionsSeries = {
            name:'Sessions',
            data: props.data.rows.map(
                (v:any)=>{
                    return Number(v.metrics[0].values[0])
                }
            )
        };

        const usersSeries = {
            name:'Users',
            data: props.data.rows.map(
                (v:any)=>{
                    return Number(v.metrics[0].values[1])
                }
            )
        };

        setChartData(
            {
                series: [
                    sessionsSeries,
                    usersSeries
                ],
                options: {
                    chart: {
                        height: 350,
                        type: 'line',
                        zoom: {
                            enabled: false
                        }
                    },
                    title: {
                        text: 'Analytic Data',
                        align: 'left'
                    },
                    dataLabels: {
                        enabled: false
                    },
                    markers: {
                        size: 5
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    grid: {
                        row: {
                            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                            opacity: 0.5
                        },
                    },
                    xaxis: {
                        categories: props.data.rows.map(
                            (v:any)=>{
                                const date:string = v.dimensions[0];

                                return date.substr(4,2) + '-' + date.substr(6, 2)
                            }
                        )
                    }
                }
            }
        )
        
    }, [])

    return chartData != null ? (

        <Chart options={chartData.options} series={chartData.series} height="250px" type="line"></Chart>
    ) : (<h1>Loading...</h1>)
}