import { useEffect, useState } from "react";
import { ClientInvoiceCreateModal } from "../../../components/client/client-invoice-create-modal";
import { ClientInvoiceTableObject } from "../../../components/client/client-invoice-tb";
import { ClientInvoiceUnbilledModal } from "../../../components/client/client-invoice-unbilled-modal";
import { SkeletonTable } from "../../../components/skeleton-loaders/skeleton-table";
import { useLoadingMessage } from "../../../services/hooks/loading-message";
import { HttpService } from "../../../services/HttpService";
import { Client } from "../../../services/interfaces/client.interface";

interface ClientInvoiceProps {
    client: Client,
    http: HttpService
}

interface showInvoiceModalInterface {
    oneOff: boolean,
    unbilledHours: boolean
}

export const ClientInvoices = (props: ClientInvoiceProps) => {
    let [invoices, setInvoices] = useState([]);
    const [lastId, setLastId] = useState<string>('');
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [message, completed, error, setLoadingMessage] = useLoadingMessage({ message: '', completed: false, error: false });
    const [showInvoiceModal, setShowInvoiceModal] = useState<showInvoiceModalInterface>({
        oneOff: false,
        unbilledHours: false
    });
    const [unbilledHours, setUnbilledHours] = useState<any>(null);

    const getInvoices = (skipLastId: boolean = false) => {

        setLoadingMessage("Loading...", false);

        let uri: string = `/clients/${props.client.id}/invoices`;

        if (lastId.length && !skipLastId)
            uri += `?lastInvoiceId=${lastId}`;

        props.http.get(uri).then(
            (data) => {
                setHasMore(data.hasMore);
                if (data.results.length > 1)
                    setLastId(data.results[data.results.length - 1].id);
                if (!skipLastId)
                    invoices = invoices.concat(data.results);
                else
                    invoices = data.results;
                setInvoices([...invoices]);
                setLoadingMessage("", true, false);
            }
        ).catch((e) => {
            console.error(e);
            setLoadingMessage("There was an error...", false, true);
        });
    }
    const getUnbilledHours = () => {
        props.http.get(`/clients/${props.client.id}/invoices/unbilled`).then(
            (data) => {
                if (data == null || data.length <= 0)
                    setUnbilledHours(null);
                else
                    setUnbilledHours(data);
            }
        ).catch((e) => {
            console.error(e);
        });
    }

    const modalClose = (refreshPage: boolean) => {
        setShowInvoiceModal({
            unbilledHours: false,
            oneOff: false
        });
        if (refreshPage) {
            _hardRefresh();
        }
    }
    const _hardRefresh = () => {
        setLastId('')
        getInvoices(true)
        getUnbilledHours();
    }
    const _getAllHoursNumber = () => {
        let x = 0
        unbilledHours.map(
            (v: any) => {
                x += v.qty
            }
        )
        return Number(x.toFixed(2));
    }
    useEffect(() => {
        getInvoices()
        getUnbilledHours();
    }, [])

    return (
        <>
            <div className="btn-group float-right">


                <ClientInvoiceCreateModal http={props.http} client={props.client} show={showInvoiceModal.oneOff} onHide={(refreshPages: boolean) => modalClose(refreshPages)} />
                {
                    unbilledHours && (
                        <>
                            <ClientInvoiceUnbilledModal unbilledHours={unbilledHours} http={props.http} client={props.client} show={showInvoiceModal.unbilledHours} onHide={(refreshPages: boolean) => modalClose(refreshPages)} />
                            <button className="mb-3 btn btn-outline-success " onClick={() => setShowInvoiceModal({ unbilledHours: true, oneOff: false })}>Create Invoice For Hours ({_getAllHoursNumber()})</button>
                        </>
                    )
                }
                <button className="mb-3 btn btn-outline-info" onClick={() => setShowInvoiceModal({ unbilledHours: false, oneOff: true })}>Create One Off Invoice</button>
            </div>
            <div className="mb-10">
                {
                    completed ? (
                        <>
                            <div style={{ maxHeight: 800, overflowY: 'auto' }} className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th >Created</th>
                                            <th >Attempted</th>
                                            <th >Amount</th>
                                            <th >Due Date</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            invoices.length ? invoices.map(
                                                (v: any, i: number) => {
                                                    return (
                                                        <ClientInvoiceTableObject key={i} reload={_hardRefresh} invoice={v} http={props.http} clientId={props.client.id} />
                                                    )
                                                }
                                            ) : <tr>
                                                <td className="text-center" colSpan={5}>Nothing!</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {
                                hasMore && (
                                    <button className="btn btn-success mt-2" onClick={() => getInvoices()}>More</button>
                                )
                            }

                        </>
                    ) : (completed && error) ? (<h3>{message}</h3>) : (<SkeletonTable headerNames={["Created", "Attempted", "Amount", "Due Date", "Status", ""]} />)

                }

            </div>
        </>
    )
}