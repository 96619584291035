import React from 'react';
import './App.css';
import { AppRouter } from './components/router/app-router';

export class App extends React.Component{
  render() {
      return (
        <div className="wrapper">
            <AppRouter></AppRouter>
        </div>
      );
  }
}


