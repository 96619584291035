import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react"
import { Modal } from "react-bootstrap"
import { HttpService } from "../../services/HttpService";
import { Client } from "../../services/interfaces/client.interface";

interface InvoiceItemsType {
    name: string,
    qty: number,
    cost: number,
    total: number
}

export const ClientInvoiceCreateModal = (props: { show: boolean, onHide: any, http:HttpService, client:Client }) => {
    const [invoiceItems, setInvoiceItems] = useState<InvoiceItemsType[]>([])
    const [invoiceTotal, setInvoiceTotal] = useState(0);
    const [savingInvoice, setSavingInvoice] = useState(false);

    const handleInvoiceItemChange = (key: string, value: string | number, i: number) => {

        let workingItem = invoiceItems[i];

        workingItem = Object.assign(workingItem, { [key]: value });

        if (key == 'qty' || key == 'cost') {
            workingItem.total = workingItem.qty * workingItem.cost;
        }

        invoiceItems[i] = workingItem;
        _updateTotal();

        setInvoiceItems([...invoiceItems]);
    }

    const _updateTotal = ()=>{
        let x = 0;
        invoiceItems.map(
            (item)=>{
                x += item.qty * item.cost
            }
        )
        setInvoiceTotal(x);
    }

    const addNewInvoiceItem = () => {
        invoiceItems.push(
            {
                name: "",
                cost: 0,
                qty: 0,
                total: 0
            }
        );

        _updateTotal();

        setInvoiceItems([...invoiceItems]);
    }
    const removeInvoiceItem = (i: number) => {
        invoiceItems.splice(i, 1);
        setInvoiceItems([...invoiceItems]);
    }

    const createInvoice = ()=>{

        let finalLineItems = [];

        for(let item of invoiceItems)
        {
            if(item.name.length > 2 && item.cost > 0 && item.qty > 0)
                finalLineItems.push(item);
        }

        if(!finalLineItems.length)
        {
            alert('Please make sure all line items are set!');
            return;
        }

        let con = window.confirm('Are you sure?')

        if(!con)
            return;

        setSavingInvoice(true);
        props.http.post(`/clients/${props.client.id}/invoices`, finalLineItems).then(
            (res)=>{
                alert('Invoice created!')
                closeModal(true);
            }
        ).catch(
            (err)=>{
                alert(err);
                setSavingInvoice(false);
            }
        )

    } 

    const closeModal = (refreshPage:boolean = false) => {
        setInvoiceItems([]);
        setSavingInvoice(false);
        props.onHide(refreshPage);
    }
    return (
        <Modal
            size="lg"
            show={props.show}
            onHide={closeModal}
        >
            <Modal.Header closeButton>
                <Modal.Title>Create Invoice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <h4>Line Items</h4>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Item Name</th>
                                    <th>Qty</th>
                                    <th>Cost</th>
                                    <th>Total</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    invoiceItems.map(
                                        (item, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <input className="form-control" value={item.name} placeholder="Item Line Name" onChange={(e) => handleInvoiceItemChange('name', e.target.value, i)} />
                                                    </td>
                                                    <td>
                                                        <input style={{ width: '45px' }} className="form-control" value={item.qty} onChange={(e) => handleInvoiceItemChange('qty', Number(e.target.value), i)} />
                                                    </td>
                                                    <td>
                                                        <input style={{ width: '90px' }} className="form-control" value={item.cost} onChange={(e) => handleInvoiceItemChange('cost', Number(e.target.value), i)} />
                                                    </td>
                                                    <td>
                                                        <input style={{ width: '100px' }} readOnly={true} className="form-control" value={item.total} />
                                                    </td>
                                                    <td>
                                                        <button className="btn btn-danger btn-small" disabled={savingInvoice} onClick={() => removeInvoiceItem(i)}>
                                                            <FontAwesomeIcon icon={faTrash} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    )
                                }
                            </tbody>
                        </table>
                        <button className="btn btn-success w-100" disabled={savingInvoice} onClick={() => addNewInvoiceItem()}>Add Invoice Item</button>
                    </div>
                    <div className="col-12 mt-5">
                        <p className="text-center"><b>Invoice Total:</b> ${invoiceTotal}</p>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-sm-12 col-md-6">
                                <button className="btn btn-danger w-100" disabled={savingInvoice} onClick={()=>closeModal()}>Cancel</button>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <button className="btn btn-success w-100" disabled={savingInvoice} onClick={()=>createInvoice()}>Create Invoice</button>
                            </div>
                        </div>

                    </div>
                </div>
            </Modal.Body>

        </Modal>
    )
}