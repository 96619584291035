import { useContext, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { Client } from "../../../services/interfaces/client.interface";
import { HttpServiceContext } from "../../../services/context/HttpServiceContext";
import { HttpService } from "../../../services/HttpService";
import { EditClientsInfo } from "./info";
import { EditClientsProjects } from "./projects";
import { ClientInvoices } from "./invoices";
import { PageDetailsContext } from "../../../services/context/PageDetailsContext";
import { ClientTimeTrackers } from "./time";

export const EditClientsIndex = () => {
    const { id } = useParams<any>();
    const [pageState, dispatchPage] = useContext(PageDetailsContext);
    const history = useHistory();
    const [client, setClient] = useState<Client>();
    const httpService: HttpService = useContext(HttpServiceContext);

    useEffect(() => {
        dispatchPage(
            {
                payload: () => (
                    <h1>Client &gt; ...</h1>
                )
            }
        )
        httpService.get(`/clients/single/${id}`).then(
            (client: Client) => {
                setClient(client);
                dispatchPage(
                    {
                        payload: () => (
                            <h1>Client &gt; {client.clientName}</h1>
                        )
                    }
                )
            }
        ).catch(
            (e) => {
                alert(e);
                history.push('/clients');
            }
        );
    }, [])

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-12">
                            <Tabs defaultActiveKey="projects">
                                <Tab eventKey="projects" title="Projects">
                                    <EditClientsProjects id={id} />
                                </Tab>
                                <Tab eventKey="info" title="Info">
                                    <EditClientsInfo client={client} httpService={httpService} setClient={setClient} />
                                </Tab>
                                {
                                    (client && client.stripeCustomerId) && (
                                        <Tab eventKey="invoices" title="Invoices" unmountOnExit>
                                            <ClientInvoices client={client} http={httpService} />
                                        </Tab>
                                    )
                                }
                                {
                                    client && (
                                        <Tab eventKey="time" title="Time Entries" unmountOnExit>
                                            <ClientTimeTrackers client={client} http={httpService} />
                                        </Tab>
                                    )
                                }

                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}