import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export interface SkeletonTableProps {
    rows?: number,
    cols?: number,
    height?: number,
    headerNames?: string[]
}

export const SkeletonTable = (props: SkeletonTableProps) => {

    const { rows = 4, cols = 4, height = 30, headerNames = [] } = props;

    return (
        <div className='table-responsive'>
            <table className='table'>
                <thead>
                    <tr>
                        {
                            headerNames.length <= 0 ?
                                Array(cols).fill(0).map(() => (<th><Skeleton height={height} /></th>))
                                :
                                headerNames.map((name) => (<th>{name}</th>))
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        Array(rows).fill(0).map(
                            (v, i) => (
                                <tr key={i}>
                                    {
                                        headerNames.length <= 0 ?
                                            Array(cols).fill(0).map(() => (<td><Skeleton height={height} /></td>))
                                            :
                                            headerNames.map(() => (<td><Skeleton height={height} /></td>))
                                    }
                                </tr>
                            )
                        )
                    }
                </tbody>
            </table>
        </div>

    )

}