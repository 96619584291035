import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useForm } from 'react-hook-form';
import { useErrorMessage } from "../../services/hooks/error-message";
import { HttpServiceContext } from "../../services/context/HttpServiceContext";
import { HttpService } from "../../services/HttpService";
import { PageDetailsContext } from "../../services/context/PageDetailsContext";
import { TopbarTitleSetter } from "../../components/topbar/topbar-title-setter";

interface clientFormData {

}

export const ClientsCreate = () => {

    TopbarTitleSetter((
        <h1>Create Client</h1>
    ))

    const httpService: HttpService = useContext(HttpServiceContext);
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useErrorMessage("");
    const { register, handleSubmit, formState: { errors, isValid } } = useForm({ mode: 'all' });
    const onSubmit = (data: clientFormData) => {
        setIsLoading(true);

        httpService.post("/clients", {
            client: data
        })
            .then((id:any) => {
                setIsLoading(false);
                alert('Client Created!');
                history.push('/clients/edit/'+id);
            })
            .catch((e) => {
                if (Array.isArray(e)) {
                    setErrorMessage("Make sure all fields are set!");
                }
                else
                    setErrorMessage(e);
                    
                setIsLoading(false);
            })

    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">

                    <form className="" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-row">
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="client-name">Client Name</label>
                                    <input className={errors.clientName && errors.clientName.message ? "form-control is-invalid" : "form-control"} id="client-name" type="text" placeholder="Client Name" {
                                        ...register("clientName",
                                            {
                                                required: {
                                                    value: true,
                                                    message: 'Client Name is required!'
                                                },
                                                minLength: {
                                                    value: 1,
                                                    message: 'Client Names need to be at least 1 character long!'
                                                }
                                            }
                                        )} />
                                    {errors.clientName && <div className="invalid-feedback">
                                        {errors.clientName.message}
                                    </div>}
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="client-contact-name">Client Contact Name</label>
                                    <input type="text" placeholder="Client Contact Name" className={errors.contactName ? "form-control is-invalid" : "form-control"} id="client-contact-name" {
                                        ...register('contactName', {
                                            required: {
                                                value: true,
                                                message: 'Client Contact Name is required!'
                                            },
                                            minLength: {
                                                value: 1,
                                                message: 'Client Contact Names need to be at least 1 character long!'
                                            }
                                        })
                                    } />
                                    {errors.contactName && <div className="invalid-feedback">{errors.contactName.message}</div>}
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="client-contact-phone">Client Contact Phone Number</label>
                                    <input type="text" placeholder="Client Contact Phone Number" className={errors.contactPhone ? "form-control is-invalid" : "form-control"} id="client-contact-phone" {
                                        ...register('contactPhone', {
                                            maxLength: {
                                                value: 12,
                                                message: 'Please enter in a max phone number length of 12!'
                                            }
                                        })
                                    } />
                                    {errors.contactPhone && <div className="invalid-feedback">{errors.contactPhone.message}</div>}
                                </div>
                            </div>
                            <div className="col-3">
                            
                                <div className="form-group">
                                    <label htmlFor="client-contact-email">Client Contact Email</label>
                                    <input type="text" placeholder="Client Contact Email" className={errors.contactEmail ? "form-control is-invalid" : "form-control"} id="client-contact-email" {
                                        ...register('contactEmail', {
                                            required: {
                                                value: true,
                                                message: 'Client Contact Email is required!'
                                            },
                                            pattern: {
                                                value: /^\S+@\S+$/i,
                                                message: 'Not a valid email!'
                                            }
                                        })
                                    } />
                                    {errors.contactEmail && <div className="invalid-feedback">{errors.contactEmail.message}</div>}
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="client-contact-extra-email">Client Extra Emails (comma separated)</label>
                                    <input type="text" placeholder="Client Extra Emails" className={errors.contactExtraEmails ? "form-control is-invalid" : "form-control"} id="client-contact-extra-email" {
                                        ...register('contactExtraEmails')
                                    } />
                                    {errors.contactExtraEmails && <div className="invalid-feedback">{errors.contactExtraEmails.message}</div>}
                                </div>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col-6">
                                <p className="text-danger">{errorMessage.length > 0 && errorMessage}</p>
                            </div>
                            <div className="col-6">
                                <button className="btn btn-outline-success float-right" disabled={!isValid || isLoading}>Create</button>
                            </div>
                        </div>
                    </form>

                </div>
            </div>

        </div>
    );
}
