import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { SideBarToggleContext } from "../sidebarToggleContext";

type SidebarProps = {
    active: boolean,
    icon: IconProp,
    href: string,
    name: string
}

export const SidebarTab = ({ href, icon, name }: SidebarProps) => {
    const location = useLocation();
    const cleanLocationPath = location.pathname.replace(/[-\s]/g, "");
    const cleanName = name.replace(/[-\s]/g, "");

    const [toggleSidebar, setToggleSidebar] = useContext(SideBarToggleContext);
    return (
        <li>
            
            <Link to={href} onClick={() => setToggleSidebar(false)} className={cleanLocationPath.toLowerCase().includes(cleanName.toLowerCase()) ? 'active' : ''} href={href}>
                <span></span>
                <FontAwesomeIcon icon={icon} style={{ marginRight: 15 }} ></FontAwesomeIcon>
                {name}
            </Link>
        </li>
    );
}