import { Link } from "react-router-dom";
import { PaginateItemProps, PaginateItems } from "../../../components/pagination/paginate-items"
import { projectTypesKeysNumberObject } from "../../../data/project-types.data";

interface EditClientProjectsProps {
    id: number
}


export const EditClientsProjects = (props: EditClientProjectsProps) => {

    const { id } = props;

    const paginateItemSettings: PaginateItemProps = {
        fetchUri: `/projects/list?clientId=${id}`,
        createUri: `/clients/${id}/projects/create`,
        editUri: `/clients/${id}/projects/edit/:id:`,
        favoriteUri:'/projects/:id:/favorite',
        deleteUri: '/projects/:id:',
        name: "Projects",
        valueNames: {
            "Project Name": "name",
            "Type": {
                valueMap: projectTypesKeysNumberObject,
                accessor: 'type'
            },
            "Docker Port":"dockerPort"
        },
        tableOnly: true,
    }

    return (
        <>
            
            <div className="row">
                <div className="col-12">
                    <PaginateItems {...paginateItemSettings} ><Link to={`/clients/${id}/projects/create/`} className="btn btn-outline-success float-right">Create Project</Link></PaginateItems>
                </div>
            </div>
        </>
    )
}