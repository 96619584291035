import { createContext} from "react";
import { HttpService } from "../HttpService";

export const HttpServiceContext = createContext<HttpService>(undefined!);

export const HttpServiceProvider = (props:{children:any}) => {

    const httpService:HttpService = new HttpService();

    return (
        <HttpServiceContext.Provider value={httpService}>
            {props.children}
        </HttpServiceContext.Provider>
    )
}