import { createContext, useReducer} from "react";
import { initPageDetails, pageDetailsReducer } from "../reducers/pageDetails";

export const PageDetailsContext = createContext<any>(undefined);

export const PageDetailsProvider = (props:{children:any}) => {

    const reducer = useReducer(pageDetailsReducer, initPageDetails)

    return (
        <PageDetailsContext.Provider value={reducer}>
            {props.children}
        </PageDetailsContext.Provider>
    )
}