import { PaginationSelector } from "../pagination/pagination-selector"
import { TimerTableElement } from "./timer-table-element"

interface TimerTableProps {
    timeTrackers: {count:number, items:any[]},
    tableRefreshCallback: () => void,
    pageSelected: (index: number) => void,
    cloneTimeCallback?: (time:any)=>void,
}

export const TimerTable = (props: TimerTableProps) => {
    return (
        <>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Project</th>
                            <th>Time Spent</th>
                            <th>Bill Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.timeTrackers.items.length ? props.timeTrackers.items.map(
                                (v) => (
                                    <TimerTableElement key={v.id} timeTracker={v} {...props} />
                                )
                            ) : (
                                <tr>
                                    <td colSpan={5} className="text-center">No Time Trackers!</td>
                                </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
            <PaginationSelector ignoreFirstPagePush={true} onPress={props.pageSelected} perPage={10} count={props.timeTrackers.count} />
        </>
    )
}