import { useContext, useEffect, useReducer } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { Client } from "../../../services/interfaces/client.interface";
import { editProjectReducer, EDIT_PROEJECT_ACTIONS, initProjectEditValues } from "../../../services/reducers/editProjects";
import { ClientListContext } from "../../../services/context/ClientListContext";
import { HttpServiceContext } from "../../../services/context/HttpServiceContext";
import { HttpService } from "../../../services/HttpService";
import { EditProjectHome } from "./home";
import { EditProjectInfo } from "./info";
import { PageDetailsContext } from "../../../services/context/PageDetailsContext";

export const EditProjectIndex = () => {
    const { id, clientId } = useParams<any>();
    const [pageState, dispatchPage] = useContext(PageDetailsContext);
    const history = useHistory();
    const clients: Client[] = useContext(ClientListContext);
    const httpService: HttpService = useContext(HttpServiceContext);

    const [state, dispatch] = useReducer(editProjectReducer, initProjectEditValues);

    const { projectData, enablePing, isLive } = state;

    useEffect(() => {

        

        httpService.get(`/projects/${id}/single`).then(
            (project: any) => {
                const clientFormated = { value: project.client.id, label: project.client.clientName }
                dispatchPage(
                    {
                        payload:
                            ()=>(
                                <h1>
                                    Client &gt;
                                    <Link className="styleLink" to={`/clients/edit/${project.client.id}`}>{` ${project.client.clientName}`}</Link>
                                    {` > ${project.name}`}
                                    {
                                        (project != null && enablePing) && (
                                            isLive ? (
                                                <small className="text-success ml-3">(ONLINE)</small>
                                            ) : (
                                                <small className="text-danger ml-3">(OFFLINE)</small>
                                            ))
                                    }
                                </h1>
                            )
                        
                    }
                )
                dispatch({ type: EDIT_PROEJECT_ACTIONS.ENABLE_PING, payload: project.enablePing });
                dispatch({ type: EDIT_PROEJECT_ACTIONS.PROJECT_DATA, payload: project });
            }
        ).catch(
            (e)=>{
                alert(e);
                history.push('/clients/edit/'+clientId);
            }
        );
    }, [])

    return (

        projectData != null ? (
            <div className="container-fluid">
                <div className="row">
                    
                    <div className="col-12">
                        <Tabs defaultActiveKey="home">
                            <Tab eventKey="home" title="Home">
                                <EditProjectHome state={state} dispatch={dispatch} httpService={httpService}></EditProjectHome>
                            </Tab>
                            <Tab eventKey="info" title="Info">
                                <EditProjectInfo httpService={httpService} clients={clients} state={state} dispatch={dispatch}></EditProjectInfo>
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        ) : (
            <h2>Loading...</h2>
        )


    );
}