import { faCheck, faCross, faDollarSign, faPencilAlt, faPlay, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react"
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { HttpServiceContext } from "../../services/context/HttpServiceContext";
import { ProjectDefaultsContext } from "../../services/context/ProjectDefaultsContext";
import { HttpService } from "../../services/HttpService";

interface TimerTableElementProps {
    timeTracker: any,
    tableRefreshCallback: () => void,
    cloneTimeCallback?: (time: any) => void
}

export const TimerTableElement = (props: TimerTableElementProps) => {
    const [billable, setBillable] = useState<boolean>(false);
    const [billed, setBilled] = useState<boolean>(false);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [project, setProject] = useState<{ label: string, value: number } | any>(null);

    const http: HttpService = useContext(HttpServiceContext);
    const defaultSelectOptions: any[] = useContext(ProjectDefaultsContext);

    const editRefs = {
        description: useRef<any>(null),
        timeSpent: useRef<any>(null),
    }
    const loadProjects = (inputValue: string, callback: (options: any[]) => void) => {
        http.get(`/projects/list?term=${inputValue}&take=10`).then(
            (results) => {
                let [count, projects] = results;
                projects = projects.map((v: any) => {
                    return {
                        label: `${v.client.clientName} - ${v.name}`,
                        value: v.id
                    }
                })
                callback(projects);
            }
        ).catch(
            (err) => {
                alert(err);
                callback([]);
            }
        )
    }

    const calculateTimeSpent = (seconds: number) => {

        let cleanTime = {
            hours: 0,
            min: 0,
            sec: 0
        }

        cleanTime.hours = Math.floor(seconds / 3600);
        cleanTime.min = Math.floor((seconds / 60) % 60);
        cleanTime.sec = seconds % 60;


        const doubleNumber = (n: number) => ('0' + n).slice(-2)

        return `${doubleNumber(cleanTime.hours)}:${doubleNumber(cleanTime.min)}:${doubleNumber(cleanTime.sec)}`;
    }

    const deleteTime = () => {
        let con = window.confirm('Are you sure you want to delete this time tracked?');

        if (!con)
            return;

        http.delete('/time-tracking/' + props.timeTracker.id).then(
            () => props.tableRefreshCallback()
        ).catch(
            alert
        )
    }

    const updateTime = () => {

        let editObj = {
            description: editRefs.description.current.value,
            time: editRefs.timeSpent.current.value,
            billable: billable,
            billed: billed,
            projectId: project.value
        }

        const timeSpentFormatCheck = editObj.time.match(/[^:\d]/g)

        if (timeSpentFormatCheck != null) {
            alert('Please make sure that time spent is formated as "xx:xx:xx", or a variation of it!')
            return;
        }

        http.put(`/time-tracking/${props.timeTracker.id}`, editObj).then(
            () => {
                setEditMode(false);
                props.tableRefreshCallback()
            }
        ).catch(
            alert
        )

    }

    const cloneTime = () => {
        props.cloneTimeCallback && props.cloneTimeCallback(props.timeTracker)
    }

    const _viewMode = (
        <tr>
            <td>
                {props.timeTracker.description}
            </td>
            <td>
                <Link className="styleLink" to={`/clients/${props.timeTracker.project.client.id}/projects/edit/${props.timeTracker.project.id}`} >{`${props.timeTracker.project.client.clientName} - ${props.timeTracker.project.name}`}</Link>
            </td>
            <td>
                {calculateTimeSpent(props.timeTracker.totalTime)}
            </td>
            <td className="">
                <Form.Switch checked={props.timeTracker.billable} readOnly disabled={true} label="Billable" />
                <Form.Switch checked={props.timeTracker.billed} readOnly disabled={true} label="Billed" />
            </td>
            <td>
                <div className="btn-group pull-right">
                    {
                        props.cloneTimeCallback && (
                            <button className="btn btn-outline-info btn-sm" onClick={() => cloneTime()}>
                                <FontAwesomeIcon icon={faPlay} />
                            </button>
                        )
                    }
                    <button className="btn btn-outline-success btn-sm" onClick={() => setEditMode(true)}>
                        <FontAwesomeIcon icon={faPencilAlt} />
                    </button>
                    <button className="btn btn-outline-danger btn-sm" onClick={() => deleteTime()}>
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                </div>
            </td>
        </tr>
    );

    const _editMode = (
        <tr>
            <td>
                <input className="form-control" ref={editRefs.description} defaultValue={props.timeTracker.description} />
            </td>
            <td>
                <AsyncSelect
                    className="timerSelect"
                    classNamePrefix="timerSelect"
                    placeholder="Project..."
                    loadOptions={loadProjects}
                    defaultOptions={defaultSelectOptions}
                    value={project}
                    onChange={(obj) => setProject({ value: obj!.value, label: obj!.label })}
                />
            </td>
            <td>
                <input className="form-control" ref={editRefs.timeSpent} defaultValue={calculateTimeSpent(props.timeTracker.totalTime)} onKeyPress={(event) => event.key == 'Enter' && updateTime()} />
            </td>
            <td>
                <Form.Check type="switch" checked={billable} id={`${props.timeTracker.id}-billable`} onChange={() => { setBillable(!billable) }} label="Billable" />
                <Form.Check type="switch" checked={billed} id={`${props.timeTracker.id}-billed`} onChange={() => { setBilled(!billed) }} label="Billed" />
            </td>
            <td>
                <div className="btn-group pull-right">
                    <button className="btn btn-outline-success btn-sm" onClick={() => updateTime()}>
                        <FontAwesomeIcon icon={faCheck} />
                    </button>
                    <button className="btn btn-outline-danger btn-sm" onClick={() => setEditMode(false)}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>

                </div>
            </td>
        </tr>
    );

    useEffect(() => {
        setBillable(props.timeTracker.billable);
        setBilled(props.timeTracker.billed);
    }, [editMode])

    useEffect(() => {
        setProject(
            {
                value: props.timeTracker.project.id,
                label: `${props.timeTracker.project.client.clientName} - ${props.timeTracker.project.name}`
            }
        );
    }, [])


    return editMode ? _editMode : _viewMode;
}