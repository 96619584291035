enum projectTypesData {
    Web=0,
    App,
    'Data Entry',
    Watch,
    MISC
}
const projectTypesKeysValue:string[] = Object.keys(projectTypesData).filter((key:any) => isNaN(Number(projectTypesData[key])));
const projectTypesKeysString:string[] = Object.keys(projectTypesData).filter((key:any) => !isNaN(Number(projectTypesData[key])));
const projectTypesKeysNumberObject:any = {};

for(let v of projectTypesKeysValue)
{
    projectTypesKeysNumberObject[v] = projectTypesKeysString[Number(v)];
}

export {projectTypesKeysString, projectTypesKeysValue, projectTypesKeysNumberObject};