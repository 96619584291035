//loadProjects("", (options: any[]) => setDefaultSelectOptions(options))

import { createContext, useContext, useEffect, useState } from "react";
import { HttpService } from "../HttpService";
import { HttpServiceContext } from "./HttpServiceContext";

export const ProjectDefaultsContext = createContext<any[]>([]);

export const ProjectDefaults = (props:any) =>{
    const http:HttpService = useContext(HttpServiceContext);
    const [defaultSelectOptions, setDefaultSelectOptions] = useState<any[]>([])

    useEffect(() => {
        http.get(`/projects/list?take=10`).then(
            (results) => {
                let [count, projects] = results;
                projects = projects.map((v: any) => {
                    return {
                        label: `${v.client.clientName} - ${v.name}`,
                        value: v.id
                    }
                })
                setDefaultSelectOptions(projects);
            }
        ).catch(
            (err) => {
                alert(err);
            }
        )
    
      return () => {
        
      }
    }, [])
    return (
        <ProjectDefaultsContext.Provider value={defaultSelectOptions}>
            {props.children}
        </ProjectDefaultsContext.Provider>
    )
}