import { useContext, useEffect } from "react";
import { PageDetailsContext } from "../../services/context/PageDetailsContext";

export const TopbarTitleSetter = (name: any) => {
    const [pageState, dispatchPage] = useContext(PageDetailsContext);
    useEffect(() => {
        dispatchPage({
            payload:
                () => name

        })
    }, [])

}