import { useContext, useEffect, useReducer, useState } from "react";
import { ProjectStatusWidget } from "../components/project-status-widget";
import { RandomQuote } from "../components/random-quote";
import { ActiveTimer } from "../components/timer/active-timer";
import { TopbarTitleSetter } from "../components/topbar/topbar-title-setter";
import { HttpServiceContext } from "../services/context/HttpServiceContext";



export const Home = () => {

    TopbarTitleSetter((
        <h1>Home</h1>
    ))

    const [projects, setProjects] = useState<any[]>([])

    const httpService = useContext(HttpServiceContext);

    useEffect(() => {

        httpService.get('/projects/favorites').then(
            setProjects
        )
            .catch(
                (e) => {
                    console.error(e);
                }
            )

        return () => {

        }
    }, [])


    return (
        <div className="container-fluid">
            <div className="row">

                <RandomQuote />

            </div>
            <div className="row mt-5">
                <div className="col-12">
                    <ActiveTimer timerCallback={() => { }} />
                </div>
            </div>
            <div className="row mt-5 extraMargin">
                <div className="col-12">
                    <h2>Favorite Site Statuses</h2>
                </div>
                {
                    projects.map((p, i) => {

                        return (<ProjectStatusWidget project={p} key={i} />)

                    })
                }
            </div>
        </div>
    );
}