import { faCheck, faCross, faSkullCrossbones } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom";
import { HttpServiceContext } from "../services/context/HttpServiceContext";
import { HttpService } from "../services/HttpService";

interface ProjectStatusWidgetProp {
    project: any
}

export const ProjectStatusWidget = (props: ProjectStatusWidgetProp) => {
    const [pingData, setPingData] = useState<any>(null);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const history = useHistory();

    const http: HttpService = useContext(HttpServiceContext);

    const fetchProjectData = () => {
        http.get(`/projects/${props.project.id}/pings`)
            .then(setPingData)
            .catch(setErrorMessage)
    }

    useEffect(() => {
        fetchProjectData();
    }, [])


    //#region Render Helpers
    const onClick = ()=> {
        history.push(`/clients/${props.project.client.id}/projects/edit/${props.project.id}`);
    }
    const renderPingData = () => {
        return (
            <div className={`col-xs-12 col-sm-6 col-lg-3 text-center`} onClick={()=>{onClick();}}>
                <div className={`card card-hover m-2 ${pingData.isUp ? 'alert-success' : 'alert-danger'}`} style={{ borderRadius: 15, maxHeight: 180, minHeight: 180 }}>
                    <h2 className="card-title p-0 pt-2 m-0">{props.project.name}</h2>
                    <small>{props.project.client.clientName}</small>
                    <div className={`card-body pt-2`}>
                        <>
                            {
                                pingData.isUp ? (
                                    <h2><FontAwesomeIcon className="text-success" icon={faCheck} /></h2>
                                ) : (
                                    <>
                                        <h2><FontAwesomeIcon className="text-danger" icon={faSkullCrossbones} /></h2>
                                        <small><b>Last Online:</b> <i>{pingData.lastUp}</i></small><br />

                                    </>
                                )
                            }
                        </>
                        <small> <b>Avg Ping:</b> <i>{pingData.times[0].avgMs}</i></small>


                    </div>
                </div>
            </div>
        )
    }
    const renderFailToGetPingData = () => {
        return (
            <div className={`col-sm-2 col-md-2 text-center`} onClick={()=>{onClick();}}>
                <div className={`card card-hover m-2`} style={{ borderRadius: 15, maxHeight: 180, minHeight: 180 }}>
                    <h2 className="card-title p-0 pt-2 m-0">{props.project.name}</h2>
                    <small>{props.project.client.clientName}</small>
                    <div className={`card-body pt-2`}>
                        <small>{
                            errorMessage.length <= 0 ? "Loading..." : `Unable to get ping data for ${props.project.name}`
                        }</small>
                    </div>
                </div>
            </div>
        )
    }
    //#endregion

    return pingData != null ? renderPingData() : renderFailToGetPingData()

}