import { useState } from "react";

interface loadingMessageProps {
    message: string,
    completed: boolean,
    error: boolean
}

export const useLoadingMessage = (initValue: loadingMessageProps) => {
    const [message, setMessage] = useState(initValue.message);
    const [completed, setCompleted] = useState(initValue.completed);
    const [error, setError] = useState(initValue.error);

    let timeout:any = null

    const setLoadingMessage: any = (message: string, completed: boolean, error: boolean = false) => {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
            setMessage(message);
            setCompleted(completed);
            setError(error);
        }, 200)

    }
    return [message, completed, error, setLoadingMessage];
}