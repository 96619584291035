import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { PaginateItemProps, PaginateItems } from "../../components/pagination/paginate-items";
import { TopbarTitleSetter } from "../../components/topbar/topbar-title-setter";

export const ClientsList = () => {

    TopbarTitleSetter((
        <h1>Clients <Link to="/clients/create" className="btn btn-outline-success">Create Client</Link></h1>
    ))

    const paginateItemSettings: PaginateItemProps = {
        fetchUri: '/clients/list',
        editUri: '/clients/edit/:id:',
        deleteUri: '/clients/:id:',
        createUri: '/clients/create',
        name: 'Clients',
        noTitle: true,
        valueNames: {
            "Name": "clientName",
            "Contact Name": "contactName",
            "Contact Phone Number": "contactPhone",
            "Contact Email": "contactEmail"
        },
        tableOnly: false,
        minWidth: 1000
    }

    return (
        <PaginateItems {...paginateItemSettings} />
    );
}