import { useEffect, useState } from "react";
import { SkeletonTable } from "../../../components/skeleton-loaders/skeleton-table";
import { TimerTable } from "../../../components/timer/timer-table";
import { ProjectDefaults } from "../../../services/context/ProjectDefaultsContext";
import { useLoadingMessage } from "../../../services/hooks/loading-message";
import { HttpService } from "../../../services/HttpService"
import { Client } from "../../../services/interfaces/client.interface"

interface ClientTimeTrackersProps {
    client: Client | any,
    http: HttpService
}

export const ClientTimeTrackers = (props: ClientTimeTrackersProps) => {
    const { client, http } = props;
    const [timeTrackers, setTimeTrackers] = useState<{ count: number, items: any[] }>({ count: 0, items: [] })
    const [message, completed, error, setLoadingMessage] = useLoadingMessage({ message: "", completed: false, error: false })

    const loadTimeTrackers = (start: number = 0) => {
        setLoadingMessage("", false);
        http.get(`/clients/${client.id}/time-entries?start=${start}`).then(
            (res) => {
                const [count, timeTrackers] = res;
                setTimeTrackers({ count, items: timeTrackers });
                setLoadingMessage("", true);
            }
        ).catch(
            (err) => {
                setLoadingMessage("There was an error: " + err, true, true)
            }
        )
    }

    useEffect(() => {

        loadTimeTrackers();
    }, []);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 mt-2">
                    <ProjectDefaults>
                        {
                            (completed) ? (
                                <TimerTable timeTrackers={timeTrackers} tableRefreshCallback={() => loadTimeTrackers(0)} pageSelected={(i: number) => loadTimeTrackers((i - 1) * 10)} />
                            ) : (error && completed) ? <h3>{message}</h3> : <SkeletonTable headerNames={["Description", "Project", "Time Spent", "Bill Status", ""]} />
                        }
                    </ProjectDefaults>
                </div>
            </div>
        </div>
    )
}