import { useContext, useEffect } from "react";
import {
  PaginateItemProps,
  PaginateItems,
} from "../components/pagination/paginate-items";
import { TopbarTitleSetter } from "../components/topbar/topbar-title-setter";
import { PageDetailsContext } from "../services/context/PageDetailsContext";

export const SystemLogs = () => {
  TopbarTitleSetter(<h1>System Logs</h1>);

  const paginateItemSettings: PaginateItemProps = {
    fetchUri: `/system-logs/list`,
    createUri: ``,
    editUri: ``,
    deleteUri: "",
    name: "",
    valueNames: {
      Title: "title",
      Message: "message",
      Status: {
        valueMap: {
          0: "Ok",
          1: "Warning",
          2: "Error",
        },
        accessor: "status",
      },
      Created: {
        type: "time",
        format: "YYYY-MM-DD HH:MM:ss",
        accessor: "created",
      },
    },
    tableOnly: false,
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <PaginateItems {...paginateItemSettings}></PaginateItems>
      </div>
    </div>
  );
};
