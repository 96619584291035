import { faPencilAlt, faStar as starSolid, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { HttpServiceContext } from "../../services/context/HttpServiceContext";
import { HttpService } from "../../services/HttpService";
import { PaginationSelector } from "./pagination-selector";
import moment from 'moment';
import { useLoadingMessage } from "../../services/hooks/loading-message";
import { SkeletonTable } from "../skeleton-loaders/skeleton-table";
export interface PaginateItemProps {
    fetchUri: string,
    deleteUri?: string,
    editUri?: string,
    createUri?: string,
    favoriteUri?: string,
    ignoreHoverClick?: boolean,
    name: string,
    noTitle?: boolean,
    valueNames: any,
    tableOnly: boolean,
    minWidth?: number,
    children?: any
}

export const PaginateItems = (props: PaginateItemProps) => {

    const valueKeys: any = Object.keys(props.valueNames);
    const valueValues: any = Object.values(props.valueNames);
    const history = useHistory()
    const httpService: HttpService = useContext(HttpServiceContext);
    const [message, completed, error, setLoadingMessage] = useLoadingMessage({ message: "", completed: false, error: false })
    const [paginationState, setPaginationState] = useState({ start: 0, take: 10, count: 0, currentPage: 1 })
    const [items, setItems] = useState([]);

    const fetchUriCharacter: string = props.fetchUri.indexOf('?') >= 0 ? '&' : '?';

    const changePage = (toPage: number) => {

        setLoadingMessage("", false);

        paginationState.currentPage = toPage - 1;
        paginationState.start = paginationState.currentPage * paginationState.take;

        httpService.get(`${props.fetchUri}${fetchUriCharacter}start=${paginationState.start}&take=${paginationState.take}`).then(
            (d: any) => {
                const [count, items] = d;
                paginationState.count = count;
                setItems(items);
                setPaginationState(paginationState);
                setLoadingMessage("", true);
            }
        )
            .catch((err) => {
                setLoadingMessage("There was an error: " + err, true, true);
            });
    }

    const deleteItem = (data: any) => {
        if (props.deleteUri == null)
            return;

        const con = window.confirm("Are you sure you want to remove this?");

        if (!con) return;

        httpService.delete(buildUrl(props.deleteUri, data))
            .then(() => {
                alert('Successfully Removed!');
                changePage(1);
            })
            .catch(alert)
    }

    const favoriteItem = (itemData: any) => {

        if (props.favoriteUri == null)
            return;

        const con = window.confirm(`Are you sure you want to ${!itemData.favorite ? 'Favorite' : 'UnFavorite'} this?`);

        if (!con) return;

        httpService.put(buildUrl(props.favoriteUri, itemData))
            .then(() => {
                changePage(1);
            })
            .catch(alert)
    }

    const getProperty = (accessor: string, obj: any) => {
        let properties: any = Array.isArray(obj) ? accessor : accessor.split('.');
        return properties.reduce((p: any, c: any) => p && p[c], obj);
    }

    const buildUrl = (url: string, data: any) => {
        const args: any = url.matchAll(/\:(.*?)\:/g);

        let finalUrl = url;

        for (let arg of args) {
            const accessor: string = arg[1];
            const value: any = getProperty(accessor, data);
            if (value == null)
                continue;
            finalUrl = finalUrl.replace(arg[0], value);
        }

        return finalUrl;
    }

    const clickRow = (e: any, data: any) => {
        if (props.editUri == null || props.editUri.length <= 0 || props.ignoreHoverClick) return null;

        switch ((typeof e.target.className)) {
            case 'object': return null;
            case 'string':
                if (e.target.className.indexOf('btn') >= 0)
                    return null;
                break;
        }

        history.push(buildUrl(props.editUri, data))
    }

    const buildData = (value: any, item: any) => {

        let v = "";

        if (value.valueMap != null) {
            v = value.valueMap[getProperty(value.accessor, item)];
        }
        else if (value.type != null) {
            if (value.accessor == null)
                return "Missing Accessor";

            v = getProperty(value.accessor, item);

            switch (value.type) {
                case 'time':
                    v = moment(v).format(value.format != null ? value.format : 'YYYY-MM-DD');
                    break;
            }
        }
        else {
            v = getProperty(value, item);
        }


        return v;
    }



    return !props.tableOnly ? (
        <div className="container-fluid">

            <div className="row">

                <div className="col-12">
                    <div className="table-responsive">
                        {
                            completed ? (
                                <table style={props.minWidth != null ? { minWidth: props.minWidth } : {}} className={props.editUri != null && props.editUri.length > 0 ? "table table-hover" : 'table'}>
                                    <thead className="">
                                        <tr>
                                            {
                                                valueKeys.map((keys: string, i: number) => (
                                                    <th key={i}>{keys}</th>
                                                ))
                                            }
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            items.length <= 0 ? (
                                                <tr>
                                                    <td colSpan={valueKeys.length + 1} className="text-center" >Empty D:</td>
                                                </tr>
                                            ) : items.map((c: any) => (
                                                <tr key={c.id} onClick={(e) => { clickRow(e, c) }}>
                                                    {
                                                        valueValues.map((values: any, i: number) => (
                                                            <td className="align-middle" key={i}>{buildData(values, c)}</td>
                                                        ))
                                                    }
                                                    <td>
                                                        <div className="btn-group btn-group-sm float-right">
                                                            {
                                                                (props.favoriteUri != null && props.favoriteUri.length > 0) && (
                                                                    <button onClick={() => favoriteItem(c)} className="btn btn-outline-info">
                                                                        {
                                                                            c.favorite ? (
                                                                                <FontAwesomeIcon icon={starSolid} />
                                                                            ) :
                                                                                (
                                                                                    <FontAwesomeIcon icon={emptyStar} />
                                                                                )
                                                                        }
                                                                    </button>
                                                                )
                                                            }
                                                            {
                                                                (props.editUri != null && props.editUri.length > 0) && (
                                                                    <Link to={buildUrl(props.editUri, c)} className="btn btn-outline-success">
                                                                        <FontAwesomeIcon icon={faPencilAlt} />
                                                                    </Link>
                                                                )
                                                            }
                                                            {
                                                                (props.deleteUri != null && props.deleteUri.length > 0) && (
                                                                    <button onClick={() => deleteItem(c)} className="btn btn-outline-danger">
                                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                                    </button>
                                                                )
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </table>
                            ) : (error && completed) ? <h3>{message}</h3> : <SkeletonTable headerNames={valueKeys} />
                        }

                    </div>
                    <PaginationSelector perPage={10} onPress={(p) => changePage(p)} count={paginationState.count}>{props.children && (<>{props.children}</>)}</PaginationSelector>
                </div>

            </div>
        </div>
    ) : (
        <div className="table-responsive">
            {
                completed ? (
                    <table style={props.minWidth != null ? { minWidth: props.minWidth } : {}} className={props.editUri != null && props.editUri.length > 0 ? "table table-hover" : 'table'}>
                        <thead className="">
                            <tr>
                                {
                                    valueKeys.map((keys: string, i: number) => (
                                        <th key={i}>{keys}</th>
                                    ))
                                }
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                items.length <= 0 ? (
                                    <tr>
                                        <td colSpan={valueKeys.length + 1} className="text-center" >Empty D:</td>
                                    </tr>
                                ) : items.map((c: any) => (
                                    <tr key={c.id} onClick={(e) => { clickRow(e, c) }}>
                                        {
                                            valueValues.map((values: any, i: number) => (
                                                <td className="align-middle" key={i}>{buildData(values, c)}</td>
                                            ))
                                        }
                                        <td>
                                            <div className="btn-group btn-group-sm float-right">
                                                {
                                                    (props.favoriteUri != null && props.favoriteUri.length > 0) && (
                                                        <button onClick={() => favoriteItem(c)} className="btn btn-outline-info">
                                                            {
                                                                c.favorite ? (
                                                                    <FontAwesomeIcon icon={starSolid} />
                                                                ) :
                                                                    (
                                                                        <FontAwesomeIcon icon={emptyStar} />
                                                                    )
                                                            }
                                                        </button>
                                                    )
                                                }
                                                {
                                                    (props.editUri != null && props.editUri.length > 0) && (
                                                        <Link to={buildUrl(props.editUri, c)} className="btn btn-outline-success">
                                                            <FontAwesomeIcon icon={faPencilAlt} />
                                                        </Link>
                                                    )
                                                }
                                                {
                                                    (props.deleteUri != null && props.deleteUri.length > 0) && (
                                                        <button onClick={() => deleteItem(c)} className="btn btn-outline-danger">
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </button>
                                                    )
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                )
                    : (error && completed) ? <h3>{message}</h3> : <SkeletonTable headerNames={valueKeys} />
            }

            <PaginationSelector perPage={10} onPress={(p) => changePage(p)} count={paginationState.count}>{props.children && (<>{props.children}</>)}</PaginationSelector>
        </div>
    )
}