import { faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useEffect, useRef, useState } from "react";
import AsyncSelect from "react-select/async";
import { HttpService } from "../../services/HttpService";
import "../../assets/styles/timer.css";
import moment from "moment";
import { HttpServiceContext } from "../../services/context/HttpServiceContext";

interface TimeTrackerInstance {
    activeId: string,
    workingOn: string,
    startTime: string,
    projectId: number,
    projectName: string
}

export interface ActiveTimerProps {
    timerCallback:(stopped:boolean)=>void,
}

const defaultTimerInstance: TimeTrackerInstance = {
    activeId: "",
    startTime: "",
    workingOn: "",
    projectName: "",
    projectId: -1
}

export const ActiveTimer = (props: ActiveTimerProps) => {
    let timerTicker:any = null;
    const http: HttpService = useContext(HttpServiceContext);
    const [project, setProject] = useState<{label:string, value:number} | null>(null);
    const [defaultSelectOptions, setDefaultSelectOptions] = useState<any[]>([])
    const [timerString, setTimerString] = useState<string>("00:00");
    const timerInputRef = useRef<any>(null);
    const [activeTimeTracker, setActiveTimerTracker] = useState<TimeTrackerInstance>(defaultTimerInstance);

    const loadProjects = (inputValue: string, callback: (options: any[]) => void) => {
        http.get(`/projects/list?term=${inputValue}&take=10`).then(
            (results) => {
                let [count, projects] = results;
                projects = projects.map((v: any) => {
                    return {
                        label: `${v.client.clientName} - ${v.name}`,
                        value: v.id
                    }
                })
                callback(projects);
            }
        ).catch(
            (err) => {
                alert(err);
                callback([]);
            }
        )
    }
    
    const loadCachedActiveTimer = () => {
        let timerCache = localStorage.getItem('active-timer');
        if (timerCache == null)
            return;
        const activeTimer = JSON.parse(timerCache);
        setActiveTimerTracker(activeTimer);
        setProject({value:activeTimer.projectId, label:activeTimer.projectName});
        timerInputRef.current.value = activeTimer.workingOn;
    }
    const toggleActiveTimer = () => {

        if (project == null) {
            alert('Please select a project!');
            return;
        }

        return activeTimeTracker.activeId.length > 0 ? _updateTracker() : _setNewTracker();
    }

    const _updateTracker = () => {

        http.put(`/time-tracking/${activeTimeTracker.activeId}/stop`, {})
            .then(
                () => {
                    localStorage.removeItem('active-timer');
                    setActiveTimerTracker(defaultTimerInstance);
                    setProject(null);
                    timerInputRef.current.value = "";
                    props.timerCallback(true);
                }
            ).catch(
                alert
            )
    }
    const _setNewTracker = () => {
        let description: string = timerInputRef.current.value;

        http.post('/time-tracking', {
            description: description,
            projectId: project!.value
        }).then(
            (id) => {
                let newActiveTimer: TimeTrackerInstance = {
                    activeId: id,
                    workingOn: description,
                    startTime: moment().format('YYYY-MM-DD HH:mm:ss'),
                    projectId: project!.value,
                    projectName: project!.label
                }

                localStorage.setItem('active-timer', JSON.stringify(newActiveTimer));

                setActiveTimerTracker(newActiveTimer);
                props.timerCallback(false);
            }
        ).catch(
            alert
        )
    }

    const updateTimerView = (overrideTimer:any = null)=>{
        let timerObj = overrideTimer != null ? overrideTimer : activeTimeTracker;
        const diff = moment(moment()).diff(timerObj.startTime);
        const duration = moment.duration(diff);
        const display = Math.floor(duration.asHours()) + moment.utc(diff).format(':mm:ss');
        setTimerString(display);
    }

    useEffect(() => {
        loadProjects("", (options: any[]) => setDefaultSelectOptions(options));
        loadCachedActiveTimer();
        return () => {
            
        }
    }, [])

    useEffect(()=>{
        if(activeTimeTracker.activeId.length > 0)
        {
            updateTimerView();
            timerTicker = setInterval(updateTimerView, 1000);
        }
        else
        {
            setTimerString('00:00')
            clearInterval(timerTicker);
        }
        
        return ()=>{
            clearInterval(timerTicker);
        }
    },[activeTimeTracker]);


    return (
        <div className="timer">
            <input ref={timerInputRef} placeholder="What are you working on?" disabled={activeTimeTracker.activeId.length > 0} />
            <AsyncSelect
                className="timerSelect"
                classNamePrefix="timerSelect"
                placeholder="Project..."
                loadOptions={loadProjects}
                defaultOptions={defaultSelectOptions}
                value={project}
                isDisabled={activeTimeTracker.activeId.length > 0}
                onChange={(obj) => setProject({value:obj!.value, label:obj!.label})}
            />
            <p>{timerString}</p>
            {
                activeTimeTracker.activeId.length ?
                    (
                        <button className="stop" onClick={() => toggleActiveTimer()}>
                            <FontAwesomeIcon icon={faStop} />
                        </button>
                    ) :
                    (
                        <button className="play" onClick={() => toggleActiveTimer()}>
                            <FontAwesomeIcon icon={faPlay} />
                        </button>
                    )
            }

        </div>
    )
}