import { Dispatch, useEffect } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import Select from 'react-select';
import { useErrorMessage } from "../../../services/hooks/error-message";
import { Client } from "../../../services/interfaces/client.interface";
import { EDIT_PROEJECT_ACTIONS } from "../../../services/reducers/editProjects";
import { HttpService } from "../../../services/HttpService";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { projectTypesKeysString } from "../../../data/project-types.data";

interface EditProjectInfoProps {
    httpService: HttpService,
    clients: Client[],
    state: any,
    dispatch: Dispatch<any>
}

export const EditProjectInfo = (props: EditProjectInfoProps) => {

    const { httpService, clients, state, dispatch } = props;
    const { projectData, isLoading } = state;

    const [errorMessage, setErrorMessage] = useErrorMessage("");

    const { register, handleSubmit, control, reset, formState: { errors, isValid } } = useForm({
        mode: 'all', defaultValues: {}
    });
    const { fields: metaFields, append: metaAppend, remove: metaRemove } = useFieldArray({ name: 'meta', control });
    const onSubmit = (data: any) => {
        dispatch({ type: EDIT_PROEJECT_ACTIONS.IS_LOADING, payload: true });

        data['enablePing'] = data['enablePing'] == 'true';
        data['clientId'] = data['clientSelect'].value;

        httpService.put(`/projects/${projectData.id}`, {
            project: data
        })
            .then((id: any) => {
                dispatch({ type: EDIT_PROEJECT_ACTIONS.IS_LOADING, payload: false });
                alert('Project Updated!');
            })
            .catch((e) => {
                if (Array.isArray(e)) {
                    setErrorMessage("Make sure all fields are set!");
                }
                else {
                    setErrorMessage(e)
                }
                dispatch({ type: EDIT_PROEJECT_ACTIONS.IS_LOADING, payload: false });
            })

    };

    useEffect(() => {
        const clientFormated = { value: projectData.client.id, label: projectData.client.clientName }

        projectData['clientSelect'] = clientFormated;
        projectData['enablePing'] = projectData['enablePing'] ? 'true' : 'false';

        reset(projectData);
    }, [])

    return (
        <div className="row">
            <div className="col-md-12 ">

                <form className="" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-row">
                        <div className="col-md-6 col-sm-12">
                            <div className="row">
                                <div className="col-12 mt-4">
                                    <div className="form-group">
                                        <label htmlFor="project-name">Project Name</label>
                                        <input className={errors.name ? "form-control is-invalid" : "form-control"} id="project-name" type="text" placeholder="Project Name" {
                                            ...register("name",
                                                {
                                                    required: {
                                                        value: true,
                                                        message: 'Project Name is required!'
                                                    },
                                                    minLength: {
                                                        value: 1,
                                                        message: 'Project Names need to be at least 1 character long!'
                                                    }
                                                }
                                            )} />
                                        {errors.name && <div className="invalid-feedback">
                                            {errors.name.message}
                                        </div>}
                                    </div>
                                </div>
                                <div className="col-sm-12 ">
                                    <div className="form-group">
                                        <label htmlFor="project-client">Client</label>
                                        <Controller
                                            name="clientSelect"
                                            control={control}
                                            rules={{ required: true }}
                                            render={({ field }) => <Select
                                                {...field}

                                                options={clients}
                                            />

                                            }
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="project-ping">Enable Ping</label>
                                        <select className={errors.enablePing ? "form-control is-invalid" : "form-control"} id="project-ping" {
                                            ...register('enablePing',
                                                {
                                                    onChange: (e) => dispatch({ type: EDIT_PROEJECT_ACTIONS.ENABLE_PING, payload: e.target.value == 'true' })
                                                })
                                        } >
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>
                                        </select>
                                        {errors.enablePing && <div className="invalid-feedback">{errors.enablePing.message}</div>}
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-8">
                                    <div className="form-group">
                                        <label htmlFor="project-type">Project Type</label>
                                        <select className={errors.type ? "form-control is-invalid" : "form-control"} id="project-type" {
                                            ...register('type',
                                                { valueAsNumber: true })
                                        }>
                                            {
                                                projectTypesKeysString.map((v, i) => {
                                                    return (<option key={i} value={i}>{v}</option>)
                                                })
                                            }
                                        </select>
                                        {errors.type && <div className="invalid-feedback">{errors.type.message}</div>}
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="project-url">Project URL</label>
                                        <input className={errors.url && errors.url.message ? "form-control is-invalid" : "form-control"} id="project-url" type="text" placeholder="Project URL" {
                                            ...register("url",
                                                {
                                                    pattern: {
                                                        value: /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi,
                                                        message: 'Please make sure this is a valid URL!'
                                                    },
                                                    minLength: {
                                                        value: 1,
                                                        message: 'Project URLs need to be at least 1 character long!'
                                                    }
                                                }
                                            )} />
                                        {errors.url && <div className="invalid-feedback">
                                            {errors.url.message}
                                        </div>}
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="project-git">Project Git URL</label>
                                        <input className={errors.gitUrl && errors.gitUrl.message ? "form-control is-invalid" : "form-control"} id="project-git" type="text" placeholder="Project Git URL" {
                                            ...register("gitUrl", {
                                                pattern: {
                                                    value: /^(([A-Za-z0-9]+@|http(|s)\:\/\/)|(http(|s)\:\/\/[A-Za-z0-9]+@))([A-Za-z0-9.]+(:\d+)?)(?::|\/)([\d\/\w.-]+?)(\.git){1}$/i,
                                                    message: 'Please make sure this is a valid Git URL!'
                                                },
                                                minLength: {
                                                    value: 1,
                                                    message: 'Project Git URLs need to be at least 1 character long!'
                                                }
                                            })

                                        } />
                                        {errors.gitUrl && <div className="invalid-feedback">
                                            {errors.gitUrl.message}
                                        </div>}
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="project-viewid">Google View ID</label>
                                        <input className={errors.googleViewId && errors.googleViewId.message ? "form-control is-invalid" : "form-control"} id="project-viewid" type="text" placeholder="Google View ID" {
                                            ...register("googleViewId",
                                                {
                                                    minLength: {
                                                        value: 1,
                                                        message: 'Google View Id need to be at least 1 character long!'
                                                    }
                                                }
                                            )} />
                                        {errors.googleViewId && <div className="invalid-feedback">
                                            {errors.googleViewId.message}
                                        </div>}
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group">
                                        <label htmlFor="project-costPerHour">Hourly Rate</label>
                                        <input className={errors.costPerHour && errors.costPerHour.message ? "form-control is-invalid" : "form-control"} id="project-costPerHour" type="number" placeholder="Hourly Rate" {
                                            ...register("costPerHour",
                                                {
                                                    required:{
                                                        value:true,
                                                        message:"Hourly Rate needs to be at least set to 0"
                                                    }
                                                }
                                            )} />
                                        {errors.costPerHour && <div className="invalid-feedback">
                                            {errors.costPerHour.message}
                                        </div>}
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-md-6 col-sm-12">
                            <div className="form-row">
                                <div className="col-12">
                                    <div className="form-row">
                                        <div className="col-12">
                                            <button type="button" className="btn btn-small btn-success pull-right" onClick={() => { metaAppend({ ip: "", port: 0, name: "" }) }}>+</button>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-12" style={{marginTop:'6px'}}>
                                    <div className="row">
                                        <div className='col-12'>
                                            <table className="table table-borderless">

                                                <tbody>
                                                    {
                                                        metaFields.map((meta: any, i: number) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <input className={errors[`meta.${i}.name`] && errors[`meta.${i}.name`].message ? "form-control is-invalid" : "form-control"} id={`meta_${i}_name`} type="text" placeholder="Meta Name" {
                                                                            ...register(`meta.${i}.name`)
                                                                        } />
                                                                    </td>
                                                                    <td>
                                                                        <input className={errors[`meta.${i}.ip`] && errors[`meta.${i}.ip`].message ? "form-control is-invalid" : "form-control"} id={`meta_${i}_ip`} type="text" placeholder="Meta IP" {
                                                                            ...register(`meta.${i}.ip`)
                                                                        } />
                                                                    </td>
                                                                    <td>
                                                                        <input className={errors[`meta.${i}.port`] && errors[`meta.${i}.port`].message ? "form-control is-invalid" : "form-control"} id={`meta_${i}_port`} type="text" placeholder="Meta Port" {
                                                                            ...register(`meta.${i}.port`, { valueAsNumber: true })
                                                                        } />
                                                                    </td>
                                                                    <td>
                                                                        <button type="button" onClick={() => metaRemove(i)} className="btn btn-outline-danger">
                                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="form-row">
                        <div className="col-6">
                            <p className="text-danger">{errorMessage.length > 0 && errorMessage}</p>
                        </div>
                        <div className="col-6">
                            <button className="btn btn-outline-success float-right" disabled={!isValid || isLoading}>Update</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}