import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { HttpService } from "../../services/HttpService";
import { Client } from "../../services/interfaces/client.interface";

interface InvoiceItemsType {
  name: string;
  qty: number;
  cost: number;
  total: number;
  workingItems?: string[];
}

export const ClientInvoiceUnbilledModal = (props: {
  show: boolean;
  onHide: any;
  http: HttpService;
  client: Client;
  unbilledHours: any[];
}) => {
  const [invoiceItems, setInvoiceItems] = useState<InvoiceItemsType[]>([]);
  const [invoiceTotal, setInvoiceTotal] = useState(0);
  const [savingInvoice, setSavingInvoice] = useState(false);

  const handleInvoiceItemChange = (
    key: string,
    value: string | number,
    i: number
  ) => {
    let workingItem = invoiceItems[i];

    workingItem = Object.assign(workingItem, { [key]: value });

    if (key == "qty" || key == "cost") {
      workingItem.total = workingItem.qty * workingItem.cost;
    }

    invoiceItems[i] = workingItem;
    _updateTotal();

    setInvoiceItems([...invoiceItems]);
  };

  const _updateTotal = () => {
    let x = 0;
    invoiceItems.map((item) => {
      x += item.qty * item.cost;
    });
    setInvoiceTotal(x);
  };

  useEffect(() => {
    invoiceItems.length = 0;
    for (let item of props.unbilledHours) {
      invoiceItems.push({
        name: item.name,
        qty: item.qty,
        cost: item.cost,
        total: item.qty * item.cost,
        workingItems: item.workingItems,
      });
    }
    setInvoiceItems(invoiceItems);
    _updateTotal();
  }, [props.show]);

  const createInvoice = () => {
    let finalLineItems = [];

    for (let item of invoiceItems) {
      if (item.name.length > 2 && item.cost > 0 && item.qty > 0)
        finalLineItems.push(item);
    }
    console.log(finalLineItems)
    if (!finalLineItems.length) {
      alert("Please make sure all line items are set!");
      return;
    }

    let con = window.confirm("Are you sure?");

    if (!con) return;

    setSavingInvoice(true);
    props.http
      .post(
        `/clients/${props.client.id}/invoices?unbilled=true`,
        finalLineItems
      )
      .then((res) => {
        alert("Invoice created!");
        closeModal(true);
      })
      .catch((err) => {
        alert(err);
        setSavingInvoice(false);
      });
  };

  const closeModal = (refreshPage: boolean = false) => {
    setInvoiceItems([]);
    setSavingInvoice(false);
    props.onHide(refreshPage);
  };
  return (
    <Modal size="lg" show={props.show} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Create Billable Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-12">
            <h4>Line Items</h4>
            <table className="table">
              <thead>
                <tr>
                  <th>Item Name</th>
                  <th>Qty</th>
                  <th>Cost</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {invoiceItems.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        <input
                          className="form-control"
                          value={item.name}
                          disabled
                          placeholder="Item Line Name"
                          onChange={(e) =>
                            handleInvoiceItemChange("name", e.target.value, i)
                          }
                        />
                      </td>
                      <td>
                        <input
                          style={{ width: "60px" }}
                          className="form-control"
                          disabled
                          value={item.qty}
                          onChange={(e) =>
                            handleInvoiceItemChange(
                              "qty",
                              Number(e.target.value),
                              i
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          style={{ width: "90px" }}
                          className="form-control"
                          value={item.cost}
                          onChange={(e) =>
                            handleInvoiceItemChange(
                              "cost",
                              Number(e.target.value),
                              i
                            )
                          }
                        />
                      </td>
                      <td>
                        <input
                          style={{ width: "100px" }}
                          readOnly={true}
                          className="form-control"
                          value={item.total}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="col-12 mt-5">
            <p className="text-center">
              <b>Invoice Total:</b> ${invoiceTotal}
            </p>
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <button
                  className="btn btn-danger w-100"
                  disabled={savingInvoice}
                  onClick={() => closeModal()}
                >
                  Cancel
                </button>
              </div>
              <div className="col-sm-12 col-md-6">
                <button
                  className="btn btn-success w-100"
                  disabled={savingInvoice}
                  onClick={() => createInvoice()}
                >
                  Create Billable Invoice
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
