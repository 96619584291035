import { faCog, faExclamationTriangle, faHamburger, faHome, faProjectDiagram, faUsers, faDoorOpen, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { RefCallback, useContext, useEffect, useState } from "react";
import { SidebarTab } from "./sidebar-tab/sidebar-tab";
import "./sidebar.css";
import { SideBarToggleContext } from "./sidebarToggleContext";
import logo from '../../logo-light.png'
import { useSwipeable } from 'react-swipeable'
export const Sidebar = () => {

    const [openMenu, setOpenMenu] = useContext(SideBarToggleContext);

    const { ref } = useSwipeable(
        {
            onSwipedLeft: () => {
                setOpenMenu(false)
            },
            onSwipedRight: () => {
                setOpenMenu(true)
            },
            trackMouse: true
        }
    ) as { ref: RefCallback<Document> };

    useEffect(() => {
        //ref(document);
    });
    return (
        <>

            <div className={openMenu ? 'sidebarBlocker open' : 'sidebarBlocker'} onClick={() => setOpenMenu(!openMenu)}></div>
            <div className={openMenu ? 'sidebar open' : 'sidebar'} >
                <h2 className="mt-2 mb-2"><img className="logo" src={logo} /></h2>
                <ul>
                    <SidebarTab icon={faHome} href="/home" active={true} name="Home" />
                    <SidebarTab icon={faProjectDiagram} href="/all-projects" active={false} name="All Projects" />
                    <SidebarTab icon={faUsers} href="/clients" active={false} name="Clients" />
                    <SidebarTab icon={faClock} href="/time-tracking" active={false} name="Time Tracking" />
                    <SidebarTab icon={faExclamationTriangle} href="/system-logs" active={false} name="System Logs" />
                    <SidebarTab icon={faCog} href="/settings" active={false} name="Settings" />
                </ul>
                <a href="/logout" className="logoutBtn">
                    <FontAwesomeIcon icon={faDoorOpen} />
                    <span>LogOut</span>
                </a>
            </div>
            <div className={openMenu ? 'menu-button open' : 'menu-button'}>
                <button type="button" onClick={() => setOpenMenu(!openMenu)}><FontAwesomeIcon icon={faHamburger}></FontAwesomeIcon></button>
            </div>
        </>
    )
}
