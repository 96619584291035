import { createContext, useContext, useEffect, useState } from "react";
import { Client } from "../interfaces/client.interface";
import { HttpService } from "../HttpService";
import { HttpServiceContext } from "./HttpServiceContext";



export const ClientListContext = createContext<Client[]>([]);

export const AllClientsList = (props:{children:any}) => {

    const http:HttpService = useContext(HttpServiceContext);
    const [clientList, setClientList] = useState<Client[]>([])

    useEffect(() => {
        http.get(`/clients/list?start=0&take=99`).then(
            (data:any)=>{
                let [count, clients] = data;

                clients = clients.map(
                    (c:any)=>{
                        return {
                            label:c.clientName,
                            value:c.id
                        }
                    }
                )

                setClientList(clients);
            }
        )
        .catch(
            alert
        )
        
    }, [])

    return (
        <ClientListContext.Provider value={clientList}>
            {props.children}
        </ClientListContext.Provider>
    )
}