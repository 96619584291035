import { useContext, useEffect, useState } from "react";
import { Accordion, AccordionCollapse, Card } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormInput } from "../components/forms/form-input.component";
import { useErrorMessage } from "../services/hooks/error-message";
import { HttpServiceContext } from "../services/context/HttpServiceContext";
import { HttpService } from "../services/HttpService";
import { TopbarTitleSetter } from "../components/topbar/topbar-title-setter";

enum CONFIG_TAGS {
  STRIPE_KEY,
  PING_NUMBER,
  TWILLIO_PHONE_SID,
  TWILLIO_SID,
  TWILLIO_SECRET,
  GG_CLIENT_EMAIL,
  GG_PRIVATE_KEY,
  GG_SCOPES,
  SEND_GRID,
  FROM_EMAIL,
  FROM_NAME,
  STRIPE_WEBHOOK_KEY,
  NOTIFICATION_TYPE,
  NOTIFICATION_EMAIL,
  NOTIFICATION_PHONE,
}

interface ConfigSettingsType {
  tag: number;
  data: string;
}

export const ConfigSettings = () => {
  TopbarTitleSetter(<h1>Settings</h1>);

  const httpService: HttpService = useContext(HttpServiceContext);

  const [defaultValues, setDefaultValues] = useState({
    stripeKey: "",
    pingNumber: "",
    twillioPhoneSid: "",
    twillioSid: "",
    twillioSecret: "",
    ggClientEmail: "",
    ggPrivateKey: "",
    ggScopes: "",
    sendGrid: "",
    fromEmail: "",
    fromName: "",
    stripeWebhookKey: "",
    notificationType: { label: "None", value: "NONE" },
    notificationEmail: "",
    notificationPhone: "",
  });

  useEffect(() => {
    httpService
      .get("/config/all")
      .then((settings: ConfigSettingsType[]) => {
        let dValue = {
          stripeKey: "",
          pingNumber: "",
          twillioPhoneSid: "",
          twillioSid: "",
          twillioSecret: "",
          ggClientEmail: "",
          ggPrivateKey: "",
          ggScopes: "",
          sendGrid: "",
          fromEmail: "",
          fromName: "",
          stripeWebhookKey: "",
          notificationType: { label: "None", value: "NONE" },
          notificationEmail: "",
          notificationPhone: "",
        };

        for (let setting of settings) {
          const settingValue: string = setting.data;

          switch (setting.tag) {
            case CONFIG_TAGS.STRIPE_KEY:
              dValue.stripeKey = settingValue;
              break;
            case CONFIG_TAGS.PING_NUMBER:
              dValue.pingNumber = settingValue;
              break;
            case CONFIG_TAGS.TWILLIO_PHONE_SID:
              dValue.twillioPhoneSid = settingValue;
              break;
            case CONFIG_TAGS.TWILLIO_SID:
              dValue.twillioSid = settingValue;
              break;
            case CONFIG_TAGS.TWILLIO_SECRET:
              dValue.twillioSecret = settingValue;
              break;
            case CONFIG_TAGS.GG_CLIENT_EMAIL:
              dValue.ggClientEmail = settingValue;
              break;
            case CONFIG_TAGS.GG_PRIVATE_KEY:
              dValue.ggPrivateKey = settingValue;
              break;
            case CONFIG_TAGS.GG_SCOPES:
              dValue.ggScopes = settingValue;
              break;
            case CONFIG_TAGS.SEND_GRID:
              dValue.sendGrid = settingValue;
              break;
            case CONFIG_TAGS.FROM_EMAIL:
              dValue.fromEmail = settingValue;
              break;
            case CONFIG_TAGS.FROM_NAME:
              dValue.fromName = settingValue;
              break;
            case CONFIG_TAGS.STRIPE_WEBHOOK_KEY:
              dValue.stripeWebhookKey = settingValue;
              break;
            case CONFIG_TAGS.NOTIFICATION_EMAIL:
              dValue.notificationEmail = settingValue;
              break;
            case CONFIG_TAGS.NOTIFICATION_PHONE:
              dValue.notificationPhone = settingValue;
              break;
            case CONFIG_TAGS.NOTIFICATION_TYPE:
              dValue.notificationType = {
                label:
                  settingValue[0].toUpperCase() +
                  settingValue.toLowerCase().slice(1),
                value: settingValue,
              };
              break;
          }
        }
        setDefaultValues(dValue);
        reset(dValue);
      })
      .catch(alert);
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useErrorMessage("");
  let form = useForm({ mode: "all", defaultValues: {} });
  const {
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = form;
  const onSubmit = (data: any) => {
    setIsLoading(true);

    let sendData: any[] = [];

    let i: number = 0;
    for (let tag in defaultValues) {
      sendData.push({
        tag: i,
        data: typeof data[tag] === "object" ? data[tag].value : data[tag],
      });
      i++;
    }

    httpService
      .put("/config", {
        settings: sendData,
      })
      .then((id: any) => {
        setIsLoading(false);
        alert("Settings Updated!");
      })
      .catch((e) => {
        if (Array.isArray(e.response.data.message)) {
          setErrorMessage("Make sure all fields are set!");
        } else {
          setErrorMessage(e.response.data.message);
        }
        setIsLoading(false);
      });
  };
  const testNumber = () => {
    httpService
      .put("/config/test/sms")
      .then((success: boolean) => {
        alert("Test Sent");
      })
      .catch(() => {
        alert("Test Failed :c");
      });
  };
  const isValidToTestNumber = () => {
    return (
      ["PHONE", "ALL"].indexOf(defaultValues.notificationType.value) >= 0 &&
      defaultValues.notificationPhone.length > 0 &&
      defaultValues.twillioPhoneSid.length > 0 &&
      defaultValues.twillioSecret.length > 0 &&
      defaultValues.twillioSid.length > 0
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <form className="" onSubmit={handleSubmit(onSubmit)}>
            <Accordion className="mb-3">
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="billing">
                  Billing
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="billing">
                  <Card.Body>
                    <div className="form-row">
                      <div className="col-12">
                        <FormInput
                          form={form}
                          inputName="stripeKey"
                          placeholderText="Stripe Secret Key"
                          type="password"
                          requirements={{
                            minLength: {
                              value: 1,
                              message:
                                "Stripe Key needs to be at least 1 character long!",
                            },
                          }}
                        />
                      </div>
                      <div className="col-12">
                        <FormInput
                          form={form}
                          inputName="stripeWebhookKey"
                          placeholderText="Stripe Webhook Key"
                          type="password"
                          requirements={{
                            minLength: {
                              value: 1,
                              message:
                                "Stripe Webhook Key needs to be at least 1 character long!",
                            },
                          }}
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="email">
                  Email
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="email">
                  <Card.Body>
                    <div className="form-row">
                      <div className="col-sm-12 col-md-6">
                        <FormInput
                          form={form}
                          type="password"
                          inputName="sendGrid"
                          placeholderText="Send Grid API Token"
                          requirements={{
                            required: {
                              value: true,
                              message: "Send Grid API Token is required!",
                            },
                            minLength: {
                              value: 10,
                              message:
                                "Send Grid API Token needs to be at lease 10 digits long!",
                            },
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <FormInput
                          form={form}
                          inputName="fromEmail"
                          placeholderText="From Email"
                          requirements={{
                            required: {
                              value: true,
                              message: "From Email is required!",
                            },
                          }}
                        />
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <FormInput
                          form={form}
                          inputName="fromName"
                          placeholderText="From Name"
                          requirements={{
                            required: {
                              value: true,
                              message: "From Name is required!",
                            },
                          }}
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="sms">
                  SMS
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="sms">
                  <Card.Body>
                    <div className="form-row">
                      <div className="col-sm-12 col-md-4">
                        <FormInput
                          form={form}
                          type="password"
                          inputName="twillioPhoneSid"
                          placeholderText="Twillio Number SID"
                          requirements={{
                            required: {
                              value: true,
                              message: "Twillio Number SID is required!",
                            },
                            minLength: {
                              value: 10,
                              message:
                                "Twillio Number SID needs to be at lease 10 digits long!",
                            },
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <FormInput
                          form={form}
                          inputName="twillioSid"
                          type="input"
                          placeholderText="Twillio SID"
                          requirements={{
                            required: {
                              value: true,
                              message: "Twillio SID is required!",
                            },
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <FormInput
                          form={form}
                          inputName="twillioSecret"
                          type="password"
                          placeholderText="Twillio Secret"
                          requirements={{
                            required: {
                              value: true,
                              message: "Twillio Secret is required!",
                            },
                          }}
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="notification">
                  Notifications
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="notification">
                  <Card.Body>
                    <div className="form-row">
                      <div className="col-sm-12 col-md-4">
                        <FormInput
                          form={form}
                          type="select"
                          inputName="notificationType"
                          placeholderText="How to get notifications"
                          values={[
                            {
                              label: "None",
                              value: "NONE",
                            },
                            {
                              label: "All",
                              value: "ALL",
                            },
                            {
                              label: "Email",
                              value: "Email",
                            },
                            {
                              label: "Phone",
                              value: "PHONE",
                            },
                            {
                              label: "Discord",
                              value: "DISCORD",
                            },
                          ]}
                          requirements={{
                            required: {
                              value: true,
                              message: "Notification type Required!",
                            },
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <FormInput
                          form={form}
                          inputName="notificationEmail"
                          type="input"
                          placeholderText="Notification Email"
                          requirements={{
                            required: {
                              value: true,
                              message: "Notification Email is required!",
                            },
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-4">
                        <FormInput
                          form={form}
                          inputName="notificationPhone"
                          type="input"
                          placeholderText="Notification Phone"
                          requirements={{
                            required: {
                              value: true,
                              message: "Notification Phone is required!",
                            },
                          }}
                        />
                      </div>
                      {isValidToTestNumber() ? (
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn btn-outline-info"
                            onClick={() => {
                              testNumber();
                            }}
                          >
                            Test Out Number!
                          </button>
                        </div>
                      ) : (
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn btn-outline-info"
                            onClick={() => {
                              testNumber();
                            }}
                          >
                            Test Out Notice!
                          </button>
                        </div>
                      )}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="gg_analytics">
                  Google Analytics
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="gg_analytics">
                  <Card.Body>
                    <div className="form-row">
                      <div className="col-sm-12 col-md-6">
                        <FormInput
                          form={form}
                          inputName="ggClientEmail"
                          placeholderText="Google Client Email"
                          requirements={{
                            required: {
                              value: true,
                              message: "Google Client Email is required!",
                            },
                            email: {
                              value: true,
                              message:
                                "Google Client Email needs to be an email!",
                            },
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <FormInput
                          form={form}
                          type="password"
                          inputName="ggPrivateKey"
                          placeholderText="Google Private Key"
                          requirements={{
                            required: {
                              value: true,
                              message: "Google Private Key is required!",
                            },
                          }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <FormInput
                          form={form}
                          inputName="ggScopes"
                          placeholderText="Google Scopes"
                          requirements={{
                            required: {
                              value: true,
                              message: "Google Scopes are required!",
                            },
                          }}
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

            <div className="form-row">
              <div className="col-6">
                <p className="text-danger">
                  {errorMessage.length > 0 && errorMessage}
                </p>
              </div>
              <div className="col-6">
                <button
                  className="btn btn-outline-success float-right"
                  disabled={!isValid || isLoading}
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
