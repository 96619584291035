import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

interface SelectOptionValues {
  label: string;
  value: string;
}

export interface FormInterfaceProps {
  inputName: string;
  requirements: any;
  inputType?: string;
  placeholderText: string;
  form: any;
  type?: "input" | "select" | "password";
  values?: SelectOptionValues[];
}

export const FormInput = (props: FormInterfaceProps) => {
  const [viewPassword, setViewPassword] = useState(false);

  const {
    formState: { errors, isValid },
    register,
    control,
  } = props.form;
  const values: SelectOptionValues[] = props.values != null ? props.values : [];
  const type: string = props.type != null ? props.type : "input";
  const htmlFor: string = props.inputName + "_html-for";

  let templateValue = null;

  switch (type) {
    default:
    case "input":
      templateValue = (
        <div className="form-group">
          <label htmlFor={htmlFor}>{props.placeholderText}</label>
          <input
            className={
              errors[props.inputName] && errors[props.inputName].message
                ? "form-control is-invalid"
                : "form-control"
            }
            id={htmlFor}
            type={props.inputType && "text"}
            placeholder={props.placeholderText}
            {...register(props.inputName, props.requirements)}
          />
          {errors[props.inputName] && (
            <div className="invalid-feedback">
              {errors[props.inputName].message}
            </div>
          )}
        </div>
      );
      break;
    case "select":
      templateValue = (
        <div className="form-group">
          <label htmlFor={htmlFor}>{props.placeholderText}</label>
          <Controller
            name={props.inputName}
            control={control}
            rules={props.requirements}
            render={({ field }) => (
              <Select
                {...field}
                options={values.map((c) => {
                  return {
                    label: c.label,
                    value: c.value,
                  };
                })}
              />
            )}
          />
          {errors[props.inputName] && (
            <div className="invalid-feedback">
              {errors[props.inputName].message}
            </div>
          )}
        </div>
      );
      break;
    case "password":
      templateValue = (
        <div className="form-group">
          <label htmlFor={htmlFor}>{props.placeholderText}</label>
          <div className="input-group">
            <input
              className={
                errors[props.inputName] && errors[props.inputName].message
                  ? "form-control is-invalid"
                  : "form-control"
              }
              id={htmlFor}
              type={viewPassword ? "text" : "password"}
              placeholder={props.placeholderText}
              {...register(props.inputName, props.requirements)}
            />
            <div className="input-group-append">
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => setViewPassword(!viewPassword)}
              >
                <FontAwesomeIcon icon={!viewPassword ? faEye : faEyeSlash} />
              </button>
            </div>
          </div>
          {errors[props.inputName] && (
            <div className="invalid-feedback">
              {errors[props.inputName].message}
            </div>
          )}
        </div>
      );
      break;
  }

  return templateValue;
};
