import { useContext, useEffect } from "react";
import { PaginateItemProps, PaginateItems } from "../../components/pagination/paginate-items";
import { TopbarTitleSetter } from "../../components/topbar/topbar-title-setter";
import { projectTypesKeysNumberObject } from "../../data/project-types.data";

export const ProjectsList = () => {
    TopbarTitleSetter((
        <h1>Projects</h1>
    ))
    const paginationSettings:PaginateItemProps = {
        fetchUri:'/projects/list',
        editUri:'/clients/:client.id:/projects/edit/:id:',
        name:"",
        valueNames:{
            "Project Name":"name",
            "Type":{
                valueMap:projectTypesKeysNumberObject,
                accessor:'type'
            },
            "Client Name":"client.clientName",
            "Docker Port":"cleanMeta"
        },
        tableOnly:false,
        ignoreHoverClick:true
    }
    
    return (
        <PaginateItems {...paginationSettings}/>
    );
}