import { createContext, useState } from "react";

export const SideBarToggleContext = createContext<any>(null);

export const SideBarToggle = (props:{children:any})=>{
    const [sidebarToggle, setSidebarToggle] = useState<boolean>(false);

    return (
        <SideBarToggleContext.Provider value={[sidebarToggle, setSidebarToggle]}>
            {props.children}
        </SideBarToggleContext.Provider>
    )
};