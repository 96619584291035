import { useState } from "react"

export const useErrorMessage = (initVal:string = "") => {
    const [message, setMessage] = useState(initVal);
    const setMessageTimeout = (value:string)=>{
        setMessage(value);
        setTimeout(()=>{
            setMessage("");
        }, 3000);
    }

    return [message, setMessageTimeout] as const;
}