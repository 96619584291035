import React, { useContext, useReducer } from "react";
import { PageDetailsContext } from "../../services/context/PageDetailsContext";
import { initPageDetails, pageDetailsReducer } from "../../services/reducers/pageDetails";

export const TopBar = ()=>{
  const [state, dispatch] = useContext(PageDetailsContext);


  return (
    <div className="topBar mb-4">
      {
        state.pageComponent != null ? state.pageComponent() : ""
      }
    </div>
  )
}
