import axios from 'axios';

export class HttpService {
    private baseUrl: string = "";

    constructor() {
        this.baseUrl = process.env.REACT_APP_BASE_API_URL ?? "";
    }

    private config(): any {
        return {
            headers: {
                token: localStorage.getItem('token') ?? ""
            }
        }
    };

    public async get(url: string) {
        return this.handleResponse(axios.get(this.baseUrl + url, this.config()));
    }
    public post(url: string, data: any = null) {
        return this.handleResponse(axios.post(this.baseUrl + url, data, this.config()));
    }
    public put(url: string, data: any = null) {
        return this.handleResponse(axios.put(this.baseUrl + url, data, this.config()));
    }
    public delete(url: string) {
        return this.handleResponse(axios.delete(this.baseUrl + url, this.config()));
    }

    private async handleResponse(instance: Promise<any>) : Promise<any> {
        return new Promise((resolve, reject) => {
            instance.then(
                (res) => {
                    resolve(res.data);
                })
                .catch(
                    (err) => {
                        let message = "There was a system error! Please try again!";

                        if (err == null || err.response == null || err.response.status == null)
                            return reject(message);

                        if (err.response.status === 401)
                            window.location.pathname = '/logout';

                        if (err != null && err.response != null && err.response.data != null)
                            message = err.response.data.message;

                        reject(message);
                    }
                )
        });
    }

}