import { useEffect, useRef, useState } from "react";

interface PaginationProps {
    count: number,
    perPage: number,
    onPress: (page: number) => void,
    children?: any,
    showPages?: number,
    ignoreFirstPagePush?: boolean
}

export const PaginationSelector = (props: PaginationProps) => {

    const { ignoreFirstPagePush = false } = props;

    const pages: number = Math.ceil(props.count / props.perPage);
    const pagesToShow: number = props.showPages != null ? props.showPages : 5;

    const firstRender = useRef(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [start, setStart] = useState<number>(0);
    const [end, setEnd] = useState<number>(pagesToShow);

    useEffect(() => {
        if (start == 0)
            setEnd(pagesToShow >= pages ? pages : pagesToShow);
    }, [pages]);

    useEffect(() => {
        if (currentPage > end) {
            let newEnd: number = currentPage - 1 + pagesToShow

            if (newEnd > pages)
                newEnd = pages;

            setStart(currentPage - 1);
            setEnd(newEnd);
        }
        if (currentPage <= start) {
            let newStart: number = start - pagesToShow;

            if (newStart < 0)
                newStart = 0;

            let newEnd: number = newStart + pagesToShow;

            if (newEnd > pages)
                newEnd = pages;

            setStart(newStart);
            setEnd(newEnd);
        }

        if (firstRender.current) {
            firstRender.current = false;
            if (!ignoreFirstPagePush)
                props.onPress(currentPage);
        }
        else
            props.onPress(currentPage);
    }, [currentPage])

    const renderPrevElipse = () => {
        return (
            <li className="page-item">
                <a className="page-link" href="/" onClick={(e) => {
                    e.preventDefault();
                    setCurrentPage(start);
                }}>...</a>
            </li>
        );
    }

    const renderNextElipse = () => {
        return (
            <li className="page-item">
                <a className="page-link" href="/" onClick={(e) => {
                    e.preventDefault();

                    setCurrentPage(end + 1);
                }}>...</a>
            </li>
        )
    }

    const renderViewPages = () => {

        let numbersToShow: any[] = [];

        for (let i = start; i < end; i++) {
            numbersToShow.push(
                <li className={currentPage === (i + 1) ? "page-item active" : 'page-item'} key={i}>
                    <a href="/" onClick={(e) => {
                        e.preventDefault();
                        setCurrentPage(i + 1);
                    }} className={"page-link"}>{i + 1}</a>
                </li>
            )
        }

        return numbersToShow.map(
            (v) => v
        )
    }

    return (
        <nav>
            <ul className="pagination justif-content-start">
                <li className={currentPage - 1 > 0 ? "page-item" : "page-item disabled"}>
                    <a className="page-link" href="/" onClick={(e) => {
                        e.preventDefault();
                        setCurrentPage(currentPage - 1);
                    }}>Prev</a>
                </li>

                {start > 0 && renderPrevElipse()}

                {renderViewPages()}

                {((pages > pagesToShow) && (end < pages)) && renderNextElipse()}

                <li className={(currentPage + 1 * props.perPage < props.count) && (currentPage + 1 <= pages) ? "page-item" : "page-item disabled"}>
                    <a className="page-link" href="/" onClick={(e) => {
                        e.preventDefault();
                        setCurrentPage(currentPage + 1);
                    }}>Next</a>
                </li>

                {props.children && (<div style={{ position: 'absolute', right: 15 }}>{props.children}</div>)}
            </ul>

        </nav>
    )
}