import { Dispatch, ReducerState, useEffect, useReducer } from "react";
import { Client } from "../../../services/interfaces/client.interface";
import { EDIT_PROEJECT_ACTIONS } from "../../../services/reducers/editProjects";
import { HttpService } from "../../../services/HttpService";
import Chart from 'react-apexcharts';
import moment from "moment-timezone";
import { GoogleGraphs } from "../../../components/google-graph";
import { pageDetailsReducer } from "../../../services/reducers/pageDetails";

interface EditProjectHomeProps {
    httpService: HttpService,
    state: any,
    dispatch: Dispatch<any>
}


export const EditProjectHome = (props: EditProjectHomeProps) => {

    const { dispatch, httpService } = props;
    const { chartData, enablePing, projectData, googleData } = props.state;

    const getPingData = () => {
        httpService.get(`/projects/${projectData.id}/pings`).then(
            (data: any) => {
                dispatch({
                    type: EDIT_PROEJECT_ACTIONS.CHART_DATA, payload: {
                        series: [{
                            name: "Pings",
                            data: data.last24avg.map((v: any) => v.avgMs)
                        }],
                        options: {
                            chart: {
                                height: 350,
                                type: 'line',
                                zoom: {
                                    enabled: false
                                },
                            },
                            title: {
                                text: 'Last 24 Hour Avg MS',
                                align: 'left'
                            },
                            dataLabels: {
                                enabled: false
                            },
                            markers: {
                                size: 5
                            },
                            stroke: {
                                curve: 'smooth'
                            },
                            grid: {
                                row: {
                                    colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                                    opacity: 0.5
                                },
                            },
                            xaxis: {
                                categories: data.last24avg.map((v: any) => moment.utc(v.hour).tz('America/Detroit').format('hh:mm a')),
                            }
                        },


                    }
                });
                dispatch({ type: EDIT_PROEJECT_ACTIONS.IS_LIVE, payload: data.isUp });
            }
        )

    }
    const getAnalyticData = () => {
        httpService.get(`/projects/${projectData.id}/analytics`).then(
            (data: any) => {
                if (data != null)
                    dispatch({ type: EDIT_PROEJECT_ACTIONS.GOOGLE_DATA, payload: data.data.reports[0].data });
            }
        ).catch(
            (e) => {

            }
        );
    }

    useEffect(() => {
        getAnalyticData();
        getPingData();
        const intervalPingGrabber = setInterval(
            () => {
                getPingData();
            },
            (60 * 1000) + 1000
        );

        return () => {
            clearInterval(intervalPingGrabber);
        }
    }, [])

    return (
        <div className="row">
            {
                (chartData != null && enablePing && chartData != null && chartData.series.length > 0 && chartData.series[0].data.length) ? (
                    <div className="col-sm-12 col-lg-6">
                        <div className="">
                            <Chart options={chartData.options} series={chartData.series} height="250px" type="line"></Chart>
                        </div>
                    </div>
                ) : (
                    <div className="col-sm-12 col-lg-6">
                        <div className="text-center">
                            No Ping Data to Show!
                        </div>
                    </div>
                )
            }
            {
                googleData ? (
                    <div className="col-sm-12 col-lg-6">
                        <div className="">
                            <GoogleGraphs data={googleData}></GoogleGraphs>
                        </div>
                    </div>
                ) : (
                    <div className="col-sm-12 col-lg-6">
                        <div className="text-center">
                            No Analytic Data to Show!
                        </div>
                    </div>
                )
            }
        </div>
    )
}